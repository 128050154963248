import React from 'react';
import { StyleSheet, View, FlatList, StyleProp, ViewStyle } from 'react-native';
import { AuditTableItem } from './AuditTableItem';
import { DataTableProps } from '../common/DataTable/DataTableTypes';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';
import { ActivityDataModel } from '~/api/models/audit/models/ActivityDataModel';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { Pagination } from '~/components/common/DataTable/Pagination';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { flex1 } from '~/common/commonStyles';

interface Props {
  data: ActivityDataModel[];
  pagination?: PaginationMetaModel;
  loading?: boolean;
  onPageChanged: DataTableProps['onPageChanged'];
  listStyle?: StyleProp<ViewStyle>;
}

export const AuditTable: React.FC<Props> = ({ data, pagination, loading, onPageChanged, listStyle }) => {
  const { isMobile } = useBreakpoints();

  return (
    <View style={flex1}>
      <>
        <FlatList
          keyExtractor={(item) => `${item.id}`}
          data={data}
          renderItem={({ item }) => <AuditTableItem value={item} />}
          onEndReachedThreshold={0.2}
          ListFooterComponent={loading && <LoadingActivityIndicator />}
          contentInset={isMobile ? null : { top: 0, bottom: 0, left: 26, right: 50 }}
          contentContainerStyle={[isMobile ? null : styles.flatList]}
          style={listStyle}
          scrollEnabled
          onEndReached={
            isMobile
              ? () => onPageChanged({ page: (pagination?.current_page ?? 0) + 1, limit: pagination?.per_page || 10 })
              : null
          }
        />

        {isMobile ? null : (
          <Pagination
            page={pagination?.current_page || 1}
            totalCount={pagination?.total || 0}
            numberOfItemsPerPage={pagination?.per_page || 0}
            onPageChanged={onPageChanged}
          />
        )}
      </>
    </View>
  );
};

const styles = StyleSheet.create({
  flatList: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightGrey,
  },
});
