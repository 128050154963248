export const healthProfileLabels = {
  healthMetrics: 'Health Metrics',
  allergies: 'Allergies',
  medicalConditions: 'Medical Conditions',
  medications: 'Medications',
  procedures: 'Procedures',
  prosthesisImplantsGrafts: 'Prosthesis/Implants/Grafts',
  vaccinations: 'Vaccinations',
  lifestyle: 'Lifestyle',
  view: 'Go to My Health Profile',
  auditLogs: 'Audit Logs',
};
