import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AppointmentChatContext } from './AppointmentChatContext';
import { useSnackbarManager } from '../snackbar/SnackbarManagementContext';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { ConsultationChatEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { privateConsultationChatChannel } from '~/api/models/channel/enum/channelNames';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { ChatMessageModel, PostChatSendingRequest } from '~/api/models/consultations/models/ChatMessageModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import {
  PostChatMediaRequest,
  PostChatMessageRequest,
} from '~/api/models/consultations/requests/PostChatMessageRequest';
import { apiGetChatMessages, apiSendChatMedia, apiSendChatMessage } from '~/api/services/consultations/channels';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { channelSubscribe, channelUnsubscribe, triggerEvent } from '~/integrations/channels/PusherChannels';
import { paginatedObjectToArray } from '~/utils/arrayUtil';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { isDoctorVersion } from '~/utils/buildConfig';
import { useJoinAppointment } from '~/utils/hooks/appointments/AppointmentDetailsHook';
import { stateEndedOrPassed } from '~/utils/hooks/appointments/AppointmentStateHook';
import { usePolling } from '~/api/hooks/Polling';

interface ToSend {
  index: number;
  request: PostChatMediaRequest | PostChatMessageRequest;
}
interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}
export const AppointmentChatProvider: React.FC<Props> = ({ children, consultation }) => {
  const [paginatedMessages, setPaginatedMessages] = useState<Record<number, ChatMessageModel[]>>({});
  const [pagination, setPagination] = useState<PaginationMetaModel>();
  const [recipientTyping, setRecipientTyping] = useState(false);
  const messageIndex = useRef(0);
  const sendingRef = useRef<PostChatSendingRequest[]>([]);
  const [sending, setSending] = useState<PostChatSendingRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useUserDetails({ allowStale: true });
  const { showJoin } = useJoinAppointment(consultation);

  const { showSnackbar } = useSnackbarManager();

  const { startPolling, stopPolling, isPolling } = usePolling(() => getMessages({ page: 1 }, true), 10000);

  const handleNotification = (
    eventName: string,
    data: {
      consultationEndingData?: { minutes_remaining: number; consultation_id: number };
      chatMessageData?: ChatMessageModel;
      typing?: boolean;
    }
  ) => {
    switch (eventName) {
      case ConsultationChatEventsEnum.CONSULTATION_CHAT_MESSAGE_SENT:
        if (data.chatMessageData.sender.id !== userDetails?.account.id) getMessages({ page: 1 });
        break;
      case ConsultationChatEventsEnum.CONSULTATION_CHAT_CONSULTATION_ENDING:
        showSnackbar(`Your consultation is ending in ${data?.consultationEndingData.minutes_remaining} minutes`, {
          keyRef: `${eventName}.${data.consultationEndingData.consultation_id}.${data.consultationEndingData.minutes_remaining}`,
        });
        break;
      case ConsultationChatEventsEnum.CONSULTATION_CHAT_CLIENT_TYPING:
        setRecipientTyping(data?.typing);
        break;
    }
  };

  useEffect(() => {
    if (!consultation?.id) return;

    getMessages({ page: 1 });

    if (consultation.state === ConsultationStateEnum.Started) {
      if (!isPolling()) startPolling();
    } else if (isPolling()) {
      stopPolling();
    }

    channelSubscribe(privateConsultationChatChannel(consultation.id), handleNotification);
    return () => {
      channelUnsubscribe(privateConsultationChatChannel(consultation.id), handleNotification);
      stopPolling();
    };
  }, [consultation]);

  function generateRequest<
    T extends PostChatMediaRequest | PostChatMessageRequest = PostChatMediaRequest | PostChatMessageRequest
  >(request: T): ToSend {
    messageIndex.current += 1;
    const sendingReq = { ...request, localId: messageIndex.current };
    const newSending = [sendingReq, ...sendingRef.current];
    sendingRef.current = newSending;

    setSending(newSending);

    return { request, index: messageIndex.current };
  }

  const setErrorOnMessage = (localId: number | undefined, error: ErrorResponse) => {
    if (!localId) {
      ErrorAlert(error);
      return;
    }

    const message = sendingRef.current.find((item) => item.localId === localId);
    if (!message) return;
    message.error = error;
    setSending([...sendingRef.current]);
  };

  const removeMessageFromSending = (localId: number) => {
    const index = sendingRef.current.findIndex((item) => item.localId === localId);
    if (index < 0) return;
    const newSending = [...sendingRef.current];
    newSending.splice(index, 1);
    sendingRef.current = newSending;
    setSending([...sendingRef.current]);
  };

  const sendCompleted = async (localId: number) => {
    getMessages({ page: 1 });
    removeMessageFromSending(localId);
  };

  const retrySend = async (localId: number) => {
    try {
      const message = sendingRef.current.find((item) => item.localId === localId);
      if (!message) return;
      message.error = undefined;
      setSending([...sendingRef.current]);

      if (message.media) {
        await apiSendChatMedia({ consultationId: consultation.id, media: message.media });
      } else {
        await apiSendChatMessage({ consultationId: consultation.id, message: message.message });
      }

      await sendCompleted(message.localId);
    } catch (e) {
      setErrorOnMessage(localId, e);
    }
  };

  const sendMessage = async (message: string) => {
    let toSend;
    try {
      toSend = generateRequest({ consultationId: consultation.id, message });
      await apiSendChatMessage(toSend.request);
      await sendCompleted(toSend.index);
    } catch (e) {
      setErrorOnMessage(toSend?.index, e);
    }
  };

  const sendMedia = async (media: DocumentPickerAsset) => {
    let toSend;
    try {
      toSend = generateRequest({ consultationId: consultation.id, media });
      await apiSendChatMedia(toSend.request);
      await sendCompleted(toSend.index);
    } catch (e) {
      setErrorOnMessage(toSend?.index, e);
    }
  };

  const getMessages = async (paginationInfo?: TablePaginationInfo, noLoadWhenRequesting?: boolean) => {
    try {
      if (!noLoadWhenRequesting) setLoading(true);
      const page = paginationInfo.page ?? 1;
      const messages = await apiGetChatMessages({
        consultationId: consultation.id,
        params: {
          page,
          limit: 30,
        },
      });

      const data = messages.data.data;

      // TODO: appending without removing other pages
      // if (page === 1 && paginatedMessages['1']?.length) {
      //   const pageOneData = [...paginatedMessages['1']];
      //   for (let i = data.length - 1; i >= 0; i--) {
      //     const message = data[i];
      //     if (!pageOneData.find((m) => m.id === message.id)) {
      //       pageOneData.push(message);
      //     }
      //   }
      //   setPaginatedMessages({
      //     ...paginatedMessages,
      //     [page]: pageOneData,
      //   });
      // } else {
      if (page === 1) {
        setPaginatedMessages({
          [page]: data,
        });
      } else {
        setPaginatedMessages({
          ...paginatedMessages,
          [page]: data,
        });
      }
      // }
      setPagination(messages.data.meta);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const messages = useMemo(() => paginatedObjectToArray<ChatMessageModel>(paginatedMessages), [paginatedMessages]);

  const allMessages = useMemo(() => {
    return [...sending, ...messages];
  }, [sending, messages]);

  const setTyping = (typing: boolean) => {
    try {
      triggerEvent(
        privateConsultationChatChannel(consultation.id),
        ConsultationChatEventsEnum.CONSULTATION_CHAT_CLIENT_TYPING,
        { typing }
      );
    } catch {}
  };

  const isTimeForConsultation = useMemo(() => {
    if (
      !consultation ||
      (consultation.state === ConsultationStateEnum.Ended &&
        consultation.channel?.id !== ChannelTypeEnum.Audio &&
        consultation.channel?.id !== ChannelTypeEnum.Video)
    )
      return false;

    switch (consultation.type) {
      case ConsultationTypeEnum.CLINIC:
        return false;
      case ConsultationTypeEnum.ON_DEMAND:
        return (
          (consultation.state !== ConsultationStateEnum.Scheduled || isDoctorVersion()) &&
          !stateEndedOrPassed(consultation.state)
        );
      case ConsultationTypeEnum.HOME_VISIT:
      case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
      default:
        return showJoin;
    }
  }, [consultation?.type, consultation?.state, consultation?.channel, showJoin]);

  return (
    <AppointmentChatContext.Provider
      value={{
        messages,
        sendMedia,
        sendMessage,
        recipientTyping,
        setTyping,
        pagination,
        loading,
        getMessages,
        sending,
        allMessages,
        retrySend,
        isTimeForConsultation,
      }}>
      {children}
    </AppointmentChatContext.Provider>
  );
};
