import { useNavigation } from '@react-navigation/native';

import { useConsultationHelper } from '../helpers/HelperConsultation';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { SuccessAlert } from '~/common/commonMethods';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { screenName } from '~/utils/screenName';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';

export const useHandleConsultationEnded = () => {
  const { getConsultation } = useConsultationHelper();
  const { openModal, closeModalByName } = useModalManager();
  const { navigate } = useNavigation<NavType>();

  const closeModals = () => {
    closeModalByName(ModalName.AppointmentFeedback);
    closeModalByName(ModalName.SaveFavouriteDoctorModal);
  };

  const onSubmitFavouriteDoctorModal = async () => {
    setTimeout(() => {
      SuccessAlert(
        [appointmentLabels.endConsultationDesc],
        labels.consultationEnd,
        appointmentLabels.returnToAppointments,
        () => {
          navigate(screenName.Appointments as never);
          closeModals();
        }
      );
    }, 400);
  };

  const showDoctorFavouriting = (data: ConsultationModel) => {
    const openDoctorFeedback = () => {
      openModal(ModalName.SaveFavouriteDoctorModal, {
        doctorOrClinic: data.doctor,
        consultation: data,
        onSubmit: () => {
          closeModals();
          onSubmitFavouriteDoctorModal();
        },
        onHide: () => {
          closeModals();
          onSubmitFavouriteDoctorModal();
        },
      });
    };

    if (data.clinic) {
      openModal(ModalName.SaveFavouriteDoctorModal, {
        doctorOrClinic: data.clinic,
        consultation: data,
        onSubmit: () => {
          setTimeout(() => openDoctorFeedback(), 1000);
        },
        onHide: () => {
          setTimeout(() => openDoctorFeedback(), 1000);
        },
      });
    } else {
      openDoctorFeedback();
    }
  };

  const execute = async (consultationId: number) => {
    const consultation = await getConsultation(consultationId);
    if (!consultation) {
      return;
    } else if (consultation.state === ConsultationStateEnum.Ended) {
      AppEventHandler.emit(AppEvents.PATIENT_APPOINTMENT_ENDED, [{ consultationId: consultation.id }]);

      openModal(ModalName.AppointmentFeedback, {
        consultation,
        onSubmit: () => {
          showDoctorFavouriting(consultation);
        },
        onHide: () => {
          showDoctorFavouriting(consultation);
        },
      });
    }
  };

  const validate = (consultationId: number) => {
    return isPatientVersion() && !!consultationId;
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate(consultationId)) return;

    await execute(consultationId);
  };

  return { handleNotification };
};
