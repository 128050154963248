import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { H5TtmRegularBlack, SmallNsRegularSecondaryBlack, SmallNsSemiBoldBlack } from '../../../components/commonText';
import { DataTable, DataTableColumns } from '../../common/DataTable/DataTable';
import { DashboardCard } from '../common/DashboardCard';

import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1 } from '~/common/commonStyles';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { ConsultationTypeName } from '~/services/config';
import { colors } from '~/utils/colors';
import { DAY_MONTH_FORMAT, TIME_FORMAT, displayTimeRange, parseDateTime } from '~/utils/dateAndTime';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
import { usePageFocus } from '~/utils/hooks/FocusHook';

const REQUEST_LIST_MINIMUM = 1;
const RECORD_HEIGHT = 85;
const EXTRA_CONTENT_HEIGHT = 60;
export const RequestsList: React.FC = () => {
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { isFetching, getRequests, data } = useApprovalRequestsList();
  const { openModal } = useModalManager();
  const { showNewBadge } = useNewBadge({ timeKey: 'updated_at' });

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      getRequests();
    });
    return removeListener;
  }, []);

  usePageFocus(() => {
    getRequests();
  });

  const rowPress = (key: number, consultation: ConsultationModel) => {
    if (!key) return;
    openModal(ModalName.ApprovalRequest, {
      consultation,
      onReady: () => {
        getRequests();
      },
    });
  };

  const recordCount = useMemo(
    () => Math.max(REQUEST_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [data, containerHeight]
  );

  const slicedRequests = useMemo(() => {
    if (isFetching || !data?.length) return [];
    return data?.slice(0, recordCount) || [];
  }, [recordCount, data, isFetching]);

  const columns: DataTableColumns = [
    {
      key: 'profile',
      title: 'Profile',
      columnStyle: { maxWidth: 400, minWidth: 180 },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'reason',
      title: 'Reason',
      columnStyle: { maxWidth: 400 },
      cell: (_, itemData: ConsultationModel) =>
        itemData.reason ? (
          <SmallNsRegularSecondaryBlack numberOfLines={3} ellipsizeMode="tail" style={{ textOverflow: 'ellipsis' }}>
            {itemData.reason}
          </SmallNsRegularSecondaryBlack>
        ) : (
          <View style={flex1}>
            <H5TtmRegularBlack numberOfLines={3} ellipsizeMode="tail" style={{ textOverflow: 'ellipsis' }}>
              {ConsultationTypeName[itemData.type]}
            </H5TtmRegularBlack>
            <ConsultationTypeIcon consultation={itemData} />
          </View>
        ),
    },
    {
      key: 'date',
      title: 'Appointment',
      columnStyle: { maxWidth: 400, minWidth: 100 },
      cell: (_, itemData: ConsultationModel) => {
        if (itemData.type === ConsultationTypeEnum.ON_DEMAND) {
          return (
            <View>
              <SmallNsSemiBoldBlack>{`${
                parseDateTime(itemData.start_at, {
                  outputFormat: DAY_MONTH_FORMAT,
                }) ?? ''
              }`}</SmallNsSemiBoldBlack>
              <SmallNsRegularSecondaryBlack style={{ textWrap: 'wrap' }}>
                {displayTimeRange(
                  parseDateTime(itemData.start_at, { outputFormat: TIME_FORMAT }),
                  parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })
                )}
              </SmallNsRegularSecondaryBlack>
            </View>
          );
        } else {
          const firstAvailability = itemData?.patient_availabilities[0];
          return (
            <View>
              <SmallNsSemiBoldBlack>{`${
                parseDateTime(firstAvailability.from, {
                  outputFormat: DAY_MONTH_FORMAT,
                }) ?? ''
              }`}</SmallNsSemiBoldBlack>
              <SmallNsRegularSecondaryBlack style={{ textWrap: 'wrap' }}>
                from {parseDateTime(firstAvailability.from, { outputFormat: TIME_FORMAT })}
              </SmallNsRegularSecondaryBlack>
            </View>
          );
        }
      },
    },
    {
      key: 'actions',
      cell: () => <ArrowRight height={10} width={5} />,
      columnStyle: { maxWidth: 50, display: 'flex', flexDirection: 'row-reverse' },
    },
  ];

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  return (
    <View style={flex1} onLayout={onLayout}>
      <DashboardCard title={labels.requests} viewAllLink={screenName.Requests} style={{ flex: 1 }}>
        <DataTable
          noHorizontalScroll
          tableId="approvalRequests"
          columns={columns}
          items={slicedRequests || []}
          onRowPress={rowPress}
          page={1}
          hideHeaders
          hidePagination
          rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
          style={flex1}
          wrapperStyle={styles.table}
          rowStyle={styles.rowStyle}
          rowHoverStyle={styles.rowHoverStyle}
          loading={isFetching}
          emptyNote={labels.noRequests}
          scrollEnabled={slicedRequests?.length ? false : undefined}
        />
      </DashboardCard>
    </View>
  );
};

const styles = StyleSheet.create({
  rowStyle: {
    backgroundColor: colors.white,
    marginVertical: 5,
    borderBottomColor: colors.transparent,
    borderRadius: 6,
    paddingVertical: 5,
    marginHorizontal: 2,
  },
  rowHoverStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  table: {
    borderColor: colors.transparent,
    paddingRight: 5,
  },
  iconBackground: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 30,
    marginRight: 10,
  },
  info: {
    flexDirection: 'column',
    lineBreak: 'strict',
  },
  type: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});
