import { Link, useNavigation } from '@react-navigation/native';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { MaltaPhoneCode } from '~/api/hooks/referenceData/constants/CountryConstants';
import { mb10, mr10, mt20, textCenter } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { Button } from '~/components/commonButton';
import { H6NsRegularSecondaryBlack, H6TtmSemiBoldTheme } from '~/components/commonText';
import { EmailInputComponent } from '~/components/registration/EmailInputComponent';
import { PhoneNumberInputComponent } from '~/components/registration/PhoneNumberInputComponent';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { NavType } from '~/navigation/types';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isClinicVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { registrationLabels } from '~/utils/labels/registrationLabels';
import { screenName } from '~/utils/screenName';

interface Form {
  email?: string;
  country_code?: string;
  mobile_number?: string;
  showEmailInput?: boolean;
}
export const LoginUserIdentityScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation<NavType>();

  const allowMultipleInput = !isClinicVersion();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      mobile_number: '',
      country_code: MaltaPhoneCode,
      showEmailInput: true,
    },
  });

  const { showEmailInput } = watch();

  const onSubmit = (data: Form) => {
    const loginDetails = data.showEmailInput
      ? {
          email: data.email,
        }
      : {
          country_code: data.country_code,
          number: data.mobile_number,
        };

    dispatch(UPDATE_LOGIN_DETAILS(loginDetails));

    setTimeout(() => navigate(screenName.PassCodeLoginScreen));
  };

  const buttons = [
    allowMultipleInput ? (
      <Controller
        control={control}
        name="showEmailInput"
        render={({ field: { onChange, value } }) => (
          <LinkButton
            style={[mb10, textCenter]}
            title={
              value
                ? 'Click here if you want to login using your mobile number'
                : 'Click here if you want to login using your e-mail'
            }
            onPress={() => onChange(!value)}
          />
        )}
      />
    ) : null,
    <Button funCallback={handleSubmit(onSubmit)} disabled={isSubmitting} label={labels.signIn} />,
    <View style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }, mt20]}>
      <H6NsRegularSecondaryBlack style={[mr10, textCenter]}>
        {`${labels.dontHaveAnAccount} `}
        <Link to={{ screen: screenName.RegisterScreen } as never}>
          <H6TtmSemiBoldTheme>{labels.register.toUpperCase()}</H6TtmSemiBoldTheme>
        </Link>
      </H6NsRegularSecondaryBlack>
    </View>,
  ];
  return (
    <EnhancedRegistrationLayout
      compressed
      hideProgress
      title={registrationLabels.loginTitle}
      subtitle={showEmailInput ? registrationLabels.loginSubtitleEmail : registrationLabels.loginSubtitleMobile}
      buttons={buttons}
      keyboardButtonIndex={allowMultipleInput ? 1 : 0}
      hideRequired>
      {showEmailInput || !allowMultipleInput ? (
        <EmailInputComponent
          style={{ width: '100%', alignItems: 'flex-start' }}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
        />
      ) : (
        <PhoneNumberInputComponent
          style={{ width: '100%', alignItems: 'flex-start' }}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}
    </EnhancedRegistrationLayout>
  );
};
