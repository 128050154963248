import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { LinkButton } from '~/components/buttons/LinkButton';
import ConsultationAgendaItem from '~/components/calendar/ConsultationAgendaItem';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { useLazyTodayCalendarEventsQuery } from '~/redux/api/appointmentsApi';
import { colors } from '~/utils/colors';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const DoctorTodayAppointmentsMobile: React.FC = () => {
  const [trigger, { data, isLoading }] = useLazyTodayCalendarEventsQuery();

  const getEvents = async () => {
    trigger();
  };

  usePageFocus(() => {
    getEvents();
  });

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      getEvents();
    });
    return removeListener;
  }, []);

  const todaysEvents = useMemo(() => {
    if (isLoading || !data?.length) return [];
    return data[0]?.events?.filter(
      (event) =>
        event.eventable.state !== ConsultationStateEnum.Started && event.eventable.state !== ConsultationStateEnum.Ended
    );
  }, [data, isLoading]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.white, marginBottom: 40 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 15 }}>{labels.todaysAppointments}</H3TtmSemiBoldBlack>
        <LinkButton to={{ screen: screenName.CalendarPage } as never} title="VIEW ALL" />
      </View>
      <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      <FlashList<CalendarEventModel | { id: number }>
        data={todaysEvents}
        keyExtractor={(item) => `${item.id}`}
        ItemSeparatorComponent={() => (
          <View style={{ marginTop: 10, marginBottom: 5 }}>
            <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
          </View>
        )}
        estimatedItemSize={75}
        ListEmptyComponent={
          <H6NsRegularBlack style={{ paddingTop: 16, paddingBottom: 14 }}>
            {isLoading ? "We're getting your appointments for today" : 'You do not have any appointments today'}
          </H6NsRegularBlack>
        }
        renderItem={(item) => (
          <ConsultationAgendaItem calendarEvent={item.item as CalendarEventModel} noHorizontalPadding />
        )}
      />
      <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple, marginTop: 7 }} />
    </View>
  );
};
