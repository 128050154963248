import { useSelector } from 'react-redux';
import { useConsultationsRequestsQuery, useLazyConsultationsRequestsQuery } from '~/redux/api/requestsApi';
import { RootState } from '~/redux/reducers';
import { isDoctorVersion } from '~/utils/buildConfig';

export function useApprovalRequestsList() {
  const approvalRequests = useSelector((state: RootState) => state.requestsReducer.requests);

  const [trigger, { data, isFetching }] = useLazyConsultationsRequestsQuery(undefined);

  const getRequests = () => {
    if (!isDoctorVersion()) return;
    trigger();
  };

  return { approvalRequests, getRequests, data, isFetching };
}
