import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';

import { ClaimDescriptionForm } from '../components/ClaimDescriptionForm';
import { InsurancePolicyDetails } from '../components/InsurancePolicyDetails';
import { useInsuranceDetails } from '../hooks/useInsuranceDetails';

import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';

export const ClaimDescriptionStep: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const { loading: claimLoading, consultation, createInsuranceClaim } = useInsuranceClaimFormContext();
  const { insuranceDetails, loading: detailsLoading } = useInsuranceDetails();
  const { navigate } = useNavigation<NavType>();

  const confirm = async () => {
    try {
      setSaving(true);
      await createInsuranceClaim();
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  const isLoading = claimLoading || detailsLoading || saving;

  const buttons = CancelContinueFooterArray({
    waiting: isLoading,
    onCancel: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
    onConfirm: confirm,
  });

  return (
    <WizardLayout buttons={buttons} wide>
      <Grid grid={{ gutters: 16 }}>
        <Container alignSelf="flex-start">
          {isLoading ? (
            <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Column alignSelf="center">
                <LoadingActivityIndicator />
              </Column>
            </Row>
          ) : (
            <Row flex>
              <Column span={{ xs: 12, lg: 4 }} style={{ minWidth: 370, marginBottom: 20 }}>
                <InsurancePolicyDetails insuranceDetails={insuranceDetails} />
              </Column>
              <Column>
                <ClaimDescriptionForm consultation={consultation} padded />
              </Column>
            </Row>
          )}
        </Container>
      </Grid>
    </WizardLayout>
  );
};
