import React from 'react';
import { Route } from '@react-navigation/native';
import { HealthProfileAuditLogsMobile } from '~/pages/common/healthProfile/mobile/history/HealthProfileAuditLogsMobile';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string }>;
}

export const HealthProfileAuditLogs: React.FC<Props> = ({ route }) => {
  const patientId = Number(route.params.id);

  return (
    <HealthProfileDataProvider accountId={patientId}>
      <HealthProfileAuditLogsMobile
        backLink={{ screen: screenName.PatientDetails, params: { id: patientId } }}
        patientId={patientId}
      />
    </HealthProfileDataProvider>
  );
};
