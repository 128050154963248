import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Divider } from 'react-native-paper';

import { RequestListMobileItem } from './RequestListMobileItem';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '../../commonText';

import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { mv10 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
import { usePolling } from '~/api/hooks/Polling';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const RequestsListMobile: React.FC<Props> = ({ style }) => {
  const { isFetching, getRequests, data } = useApprovalRequestsList();
  const { openModal } = useModalManager();
  const { startPolling, stopPolling } = usePolling(getRequests, 60000);

  useEffect(() => {
    startPolling();
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      getRequests();
    });
    return () => {
      removeListener();
      stopPolling();
    };
  }, []);

  usePageFocus(() => {
    getRequests();
  });

  const slicedRequests = useMemo(() => {
    if (isFetching || !data?.length) return [];
    return data?.slice(0, 4) || [];
  }, [data, isFetching]);

  const rowPress = (consultation: ConsultationModel) => {
    openModal(ModalName.ApprovalRequest, {
      consultation,
      onReady: () => {
        getRequests();
      },
    });
  };

  return (
    <View style={[style]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 5 }}>{labels.requests}</H3TtmSemiBoldBlack>
        <LinkButton to={{ screen: screenName.Requests } as never} title="VIEW ALL" />
      </View>
      <View style={mv10}>
        <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      </View>
      <FlashList
        data={slicedRequests}
        keyExtractor={(item) => `${item.id}`}
        estimatedItemSize={75}
        ListEmptyComponent={
          <H6NsRegularBlack style={{ paddingTop: 16, paddingBottom: 14 }}>
            {isFetching ? "We're getting your appointment requests" : 'You do not have any requests'}
          </H6NsRegularBlack>
        }
        ItemSeparatorComponent={() => (
          <View style={mv10}>
            <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
          </View>
        )}
        renderItem={({ item }) => <RequestListMobileItem request={item} onPress={rowPress} />}
      />
      <View style={mv10}>
        <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      </View>
    </View>
  );
};
