import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';
import { ConsultationsIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { APPEND_REQUESTS } from '../reducers/requestsReducer';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

export const requestsApi = createApi({
  reducerPath: 'requestsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    consultationsRequests: builder.query<ConsultationsIndexResponse, void>({
      query: () => ({
        url: 'consultations',
        method: 'get',
        params: {
          filter: {
            state: [ConsultationStateEnum.RequestSubmitted],
          },
          limit: -1,
          sort: '-created_at',
        },
        data: undefined,
        headers: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(APPEND_REQUESTS(data));
        } catch (e) {
          ErrorAlert(e);
        }
      },
    }),
  }),
});

export const { useLazyConsultationsRequestsQuery, useConsultationsRequestsQuery } = requestsApi;
