import Feather from '@expo/vector-icons/Feather';
import React, { useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { TextButton } from '../commonButton';
import { H6NsRegularBlack, SmallNsRegularBlack, SmallNsSemiBoldBlack } from '../commonText';

import { ActivityActionEnum, ActivityActionIconMapping } from '~/api/models/audit/constants/ActivityActionEnum';
import { ActivityDataModel } from '~/api/models/audit/models/ActivityDataModel';
import { flex1, p10, ph10 } from '~/common/commonStyles';
import { extractAttributes, extractSubject, extractCauser } from '~/utils/auditUtil';
import { colors } from '~/utils/colors';
import { DATE_TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { UpArrow, DownArrow } from '~/components/svgImages';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { capitalize } from '~/utils/stringUtil';

interface Props {
  value: ActivityDataModel;
}

export const AuditTableItem: React.FC<Props> = ({ value }) => {
  const [showMore, setShowMore] = useState(false);
  const { isMobile } = useBreakpoints();
  const description = useMemo(() => {
    const attributes = extractAttributes(value);
    const subject = extractSubject(value);
    const causer = extractCauser(value);

    if (value.action === ActivityActionEnum.VIEWED) {
      return (attributes ? `Viewed ${attributes} in ${subject}` : `Viewed ${subject}`) + causer;
    } else {
      return (
        (attributes
          ? `${capitalize(value.action)} ${attributes} in ${subject}`
          : `${capitalize(value.action)} in ${subject}`) + causer
      );
    }
  }, [value.event]);

  const hasPropertyChanges = useMemo(
    () => value.action !== ActivityActionEnum.VIEWED && !!value.properties?.length,
    [value]
  );

  const isNegatedChanged = useMemo(
    () => value.action === ActivityActionEnum.DELETED || value.action === ActivityActionEnum.DETACHED,
    [value]
  );

  const iconName = useMemo(() => ActivityActionIconMapping[value.action], [value]);

  const extractAttributeInfo = (value: string | string[]) => {
    if (!value) return null;
    if (typeof value === 'string') {
      return <SmallNsRegularBlack>{value}</SmallNsRegularBlack>;
    } else {
      return (
        <View>
          {value.map((item, index) => (
            <SmallNsRegularBlack key={index}>{item}</SmallNsRegularBlack>
          ))}
        </View>
      );
    }
  };

  const showMoreButton = useMemo(() => {
    const handleClick = () => setShowMore(!showMore);

    return isMobile ? (
      <TouchableOpacity onPress={handleClick} style={p10}>
        {showMore ? (
          <UpArrow width={15} height={15} color={colors.info} />
        ) : (
          <DownArrow width={15} height={15} color={colors.info} />
        )}
      </TouchableOpacity>
    ) : (
      <TextButton
        label={showMore ? 'Hide' : 'Show more'}
        labelStyle={{ color: colors.info }}
        funCallback={handleClick}
        style={{ width: 90 }}
      />
    );
  }, [showMore]);

  return (
    <View style={styles.border}>
      <View style={styles.container}>
        <View style={styles.icon}>
          <Feather name={iconName as any} size={14} color={colors.info} />
        </View>
        <View style={flex1}>
          <H6NsRegularBlack>{description}</H6NsRegularBlack>
          <SmallNsRegularBlack style={styles.dateTime}>
            {parseDateTime(value.created_at, { outputFormat: DATE_TIME_FORMAT })}
          </SmallNsRegularBlack>
        </View>
        {hasPropertyChanges ? <View>{showMoreButton}</View> : null}
      </View>
      {showMore ? (
        <View style={styles.detailsContainer}>
          {value.properties.map((property, index) => (
            <View key={index} style={styles.detail}>
              <View style={[styles.detailsAttribute, styles.detailItem]}>
                <SmallNsSemiBoldBlack>{property.attribute}</SmallNsSemiBoldBlack>
              </View>
              <View style={[styles.detailsOld, styles.detailItem]}>
                {extractAttributeInfo(isNegatedChanged ? property.change : property.old_value)}
              </View>
              <View style={[styles.detailsNew, styles.detailItem]}>
                {extractAttributeInfo(isNegatedChanged ? property.old_value : property.change)}
              </View>
            </View>
          ))}
        </View>
      ) : null}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightGrey,
  },
  dateTime: { color: colors.purpleGrey, fontSize: 10 },
  icon: {
    backgroundColor: colors.lightPurple,
    borderRadius: 30,
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  showMore: {
    color: colors.info,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 40,
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 4,
    marginBottom: 2,
    maxWidth: 668,
  },
  detailItem: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    flex: 1,
    marginHorizontal: 1,
  },
  detailsAttribute: {
    backgroundColor: colors.lightPurple,
  },
  detailsOld: {
    backgroundColor: '#ffebe9',
  },
  detailsNew: {
    backgroundColor: '#e6ffec',
  },
});
