import React from 'react';
import { useController } from 'react-hook-form';
import { View } from 'react-native';

import { ConsultationViewItem } from '../../../ConsultationViewItem';

import { useHealthRecordMedia } from '~/api/hooks/consultations/HealthRecordMediaHook';
import { useMediaPolling } from '~/api/hooks/consultations/MediaPolling';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { apiHealthRecordMediaDownload } from '~/api/services/consultations/healthRecord';
import { mb10, mt10, ph10, pv20 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { colors } from '~/utils/colors';

export const HealthRecordView: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { loading, healthRecord, form } = useHealthRecordDataContext();

  const {
    field: { value: uploadedFiles },
  } = useController({
    control: form.control,
    name: 'media',
  });

  const {
    generatedFiles,
    getGeneratedMediaFiles,
    loading: loadingMedia,
  } = useHealthRecordMedia({ healthRecordId: healthRecord?.id });

  useMediaPolling(healthRecord?.id ? getGeneratedMediaFiles : null, 2000, generatedFiles?.status);

  if (
    loading ||
    loadingMedia ||
    (generatedFiles?.status !== MediaStatusEnum.COMPLETED && generatedFiles?.status !== MediaStatusEnum.FAILED)
  ) {
    return <LoadingFetchingList fullWidth />;
  }

  const handleRetry = () => {
    getGeneratedMediaFiles();
  };

  return (
    <View>
      {uploadedFiles?.map((item: MediaModel, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <ConsultationViewItem
            key={item.id}
            title={item.file_name}
            value={item}
            style={isLast ? { borderBottomWidth: 0 } : null}
          />
        );
      })}

      {generatedFiles ? (
        <ConsultationViewItem
          title="Health Record"
          value={generatedFiles?.media?.length ? generatedFiles.media[0] : undefined}
          style={{ borderBottomWidth: 0 }}
          status={generatedFiles.status}
        />
      ) : null}

      {generatedFiles?.status === MediaStatusEnum.FAILED ? (
        <View style={mb10}>
          <H6NsRegularBlack style={[mt10, mb10, { color: colors.danger }]}>
            We were unable to generate your health record files. Our team is handling the issue, please give us some
            time and try again. If the problem persists do not hesitate to contact us.
          </H6NsRegularBlack>
          <OutlineButton style={{ marginTop: 10, maxWidth: 100 }} danger funCallback={handleRetry} label="Try again" />
        </View>
      ) : null}

      {healthRecord?.id && (uploadedFiles?.length ?? 0) && (generatedFiles?.media?.length ?? 0) > 1 ? (
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <OutlineButton
            style={{ maxWidth: 120 }}
            funCallback={() => {
              apiHealthRecordMediaDownload({ id: healthRecord!.id });
            }}
            label="Download all"
          />
        </View>
      ) : null}

      {(!uploadedFiles?.length && !generatedFiles?.media && !generatedFiles?.status) || !healthRecord?.id ? (
        <View style={isMobile ? pv20 : [ph10, pv20]}>
          <H6NsRegularBlack>No health record files submitted</H6NsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};
