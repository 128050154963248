import OT, { OTError, Publisher } from '@opentok/client';
import React, { useEffect, useRef, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useOpenTokSession } from './OTSession';
import { OTPublisherEvents } from '../types/OTEvents';

import log from '~/utils/logger';

interface Props {
  style?: StyleProp<ViewStyle>;
  eventHandlers?: OTPublisherEvents;
  properties?: {
    videoTrack?: boolean;
    publishAudio?: boolean;
    publishVideo?: boolean;
    cameraPosition?: 'front' | 'back';
    audioInputSource?: string;
    videoSource?: string;
  };
  onPublishingReady: (e: OTError) => any;
}

export const OTPublisher: React.FC<Props> = ({ style, eventHandlers, properties, onPublishingReady }) => {
  const [publisher, setPublisher] = useState<Publisher>();
  const { session, token } = useOpenTokSession();
  const publisherEventsRef = useRef<Omit<OTPublisherEvents, 'error'>>();

  const tryHandleError = (e: OTError) => {
    if (!e) return;
    if (eventHandlers?.error) eventHandlers.error(e);
    else log.error(e);
  };

  useEffect(() => {
    if (!session) return;

    // Create a publisher
    const publisher = OT.initPublisher(
      'opentok-publisher',
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        publishVideo: (properties?.videoTrack || properties?.publishVideo) ?? true,
        publishAudio: properties?.publishAudio ?? true,
        style: {
          buttonDisplayMode: 'off',
        },
      },
      (e?: OTError) => {
        setPublisher(publisher);
        if (e) {
          tryHandleError(e);
        }
      }
    );

    publisherEventsRef.current = {
      mediaStopped: eventHandlers?.mediaStopped,
      videoDisabled: eventHandlers?.videoDisabled,
      videoEnabled: eventHandlers?.videoEnabled,
      videoDisableWarning: eventHandlers?.videoDisableWarning,
      videoDisableWarningLifted: eventHandlers?.videoDisableWarningLifted,
    };

    // Connect to the session
    session.connect(token, function (error) {
      if (error) {
        tryHandleError(error);
      } else {
        session.publish(publisher, (e) => {
          tryHandleError(e);
          if (onPublishingReady) onPublishingReady(e);
        });
        publisher.on(publisherEventsRef.current);
      }
    });

    return () => {
      publisher?.off(publisherEventsRef.current);
    };
  }, [session]);

  useEffect(() => {
    if (publisher) {
      const audio = properties?.publishAudio ?? true;
      publisher.publishAudio(audio);
    }
  }, [publisher, properties?.publishAudio]);

  useEffect(() => {
    if (publisher) publisher.publishVideo(((properties?.videoTrack ?? true) && properties?.publishVideo) ?? true);
  }, [publisher, properties?.publishVideo]);

  useEffect(() => {
    if (publisher) {
      publisher.setAudioSource(properties?.audioInputSource);
    }
  }, [publisher, properties?.audioInputSource]);

  useEffect(() => {
    if (publisher) {
      publisher.setVideoSource(properties?.videoSource);
    }
  }, [publisher, properties?.videoSource]);

  return <View style={style} nativeID="opentok-publisher" />;
};
