import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import ConsultationAgendaItem from '~/components/calendar/ConsultationAgendaItem';
import ConsultationAgendaItemLarge from '~/components/calendar/ConsultationAgendaItemLarge';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { useLazyOnGoingAppointmentsQuery } from '~/redux/api/appointmentsApi';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  style?: StyleProp<ViewStyle>;
  includeEnded?: boolean;
  maxCount?: number;
}
export const OngoingAppointments: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useBreakpoints();

  const [trigger, result] = useLazyOnGoingAppointmentsQuery();

  const getEvents = async () => {
    trigger({ includeEnded: props.includeEnded });
  };

  usePageFocus(() => {
    getEvents();
  }, [props.includeEnded]);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      getEvents();
    });
    return removeListener;
  }, []);

  const consultations = result?.data;
  const slicedConsultations = useMemo(() => {
    if (!props.maxCount) return consultations ?? [];

    return consultations?.slice(0, props.maxCount) ?? [];
  }, [consultations, props.maxCount]);

  if (!consultations?.length) return null;
  return (
    <View style={props.style}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 15 }}>{labels.ongoingConsultations}</H3TtmSemiBoldBlack>
      </View>
      <FlashList
        data={slicedConsultations}
        estimatedItemSize={100}
        keyExtractor={(item) => `${item.id}`}
        ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
        renderItem={({ item }) =>
          isMobile || isTablet ? (
            <ConsultationAgendaItem showStartDate consultation={item} noHorizontalPadding />
          ) : (
            <ConsultationAgendaItemLarge showStartDate consultation={item} noHorizontalPadding />
          )
        }
      />
    </View>
  );
};
