import React, { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mt10, p15 } from '~/common/commonStyles';
import {
  H5TtmRegularBlack,
  H5TtmRegularTheme,
  H5TtmSemiBoldDisableColor,
  H6NsRegularBlack,
} from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { MandatoryAsterisks } from '~/components/inputs/MandatoryAsterisks';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Splitter } from '~/components/misc/Splitter';
import { RadioButtonsYesNo } from '~/constants/radioButtons/radioButtonOptions';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import {
  maxDateValidation,
  maxLengthValidation,
  requiredValidation,
  validationSchema,
  valuePresentValidation,
} from '~/services/validationConfig';
import { Column, Row } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  consultation: ConsultationModel;
  padded?: boolean;
}

export const ClaimDescriptionForm: React.FC<Props> = ({ consultation, padded }) => {
  const { control } = useInsuranceClaimFormContext();
  const { isMobile } = useBreakpoints();

  const { is_cost_recoverable_from_other_insurance, is3rd_party_liable } = useWatch({ control });

  const symptoms = useMemo(() => {
    return consultation?.patient_symptoms?.map(extractClinicalTermDescription).join(', ');
  }, [consultation?.patient_symptoms]);
  return (
    <View style={[styles.container, isMobile ? null : { borderWidth: 1 }]}>
      {isMobile ? null : (
        <View style={[styles.titleContainer, padded ? p15 : null]}>
          <H6NsRegularBlack>Claim Description</H6NsRegularBlack>
        </View>
      )}
      <View style={padded ? p15 : null}>
        <Row>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <H5TtmSemiBoldDisableColor>Symptoms</H5TtmSemiBoldDisableColor>
            <H5TtmRegularTheme>{symptoms || 'No symptoms listed'}</H5TtmRegularTheme>
            <H5TtmSemiBoldDisableColor style={mt10}>Reason for consultation</H5TtmSemiBoldDisableColor>
            <H5TtmRegularTheme>{consultation?.reason || 'No reason given'}</H5TtmRegularTheme>
          </Column>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  maxLength={validationSchema.string.maxLength}
                  multiline
                  placeholder="Write any additional information here"
                />
              )}
              rules={{
                maxLength: maxLengthValidation(validationSchema.string.maxLength),
              }}
            />
          </Column>
        </Row>
        {isMobile ? null : <Splitter />}

        <H5TtmRegularBlack>Date of the first visit to any doctor for this condition</H5TtmRegularBlack>
        <View style={{ maxWidth: 300 }}>
          <Controller
            name="first_doctor_visit_date"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <DatePicker
                showMandatory
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
                onChangeValue={onChange}
                value={value}
                label="DD/MM/YYYY"
              />
            )}
            rules={{
              required: requiredValidation('Date'),
              validate: {
                maxDateValidation: maxDateValidation(new Date(), 'Date of the first visit must be before today'),
              },
            }}
          />
        </View>
        {isMobile ? null : <Splitter />}
        <Row>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <H5TtmRegularBlack>
              Was illness or injury sustained as a result of an accident caused by a Third Party (which you may have the
              right to recover costs from)?
              <MandatoryAsterisks isMandatory />
            </H5TtmRegularBlack>

            <Controller
              name="is3rd_party_liable"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <RadioButtons
                  items={RadioButtonsYesNo}
                  selected={value}
                  onValueChange={onChange}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  horizontal
                  style={mt10}
                />
              )}
              rules={{
                validate: {
                  present: valuePresentValidation('Answer'),
                },
              }}
            />
          </Column>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <Controller
              name="is3rd_party_liable_comments"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  maxLength={validationSchema.string.maxLength}
                  multiline
                  placeholder="Write details here"
                  showMandatory={is3rd_party_liable}
                />
              )}
              rules={{
                maxLength: maxLengthValidation(validationSchema.string.maxLength),
                required: is3rd_party_liable ? requiredValidation('More detail') : undefined,
              }}
            />
          </Column>
        </Row>
        {isMobile ? null : <Splitter />}
        <Row>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <H5TtmRegularBlack>
              Are any costs incurred recoverable under another insurance policy? <MandatoryAsterisks isMandatory />
            </H5TtmRegularBlack>

            <Controller
              name="is_cost_recoverable_from_other_insurance"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <RadioButtons
                  items={RadioButtonsYesNo}
                  selected={value}
                  onValueChange={onChange}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  horizontal
                  style={mt10}
                />
              )}
              rules={{
                validate: {
                  present: valuePresentValidation('Answer'),
                },
              }}
            />
          </Column>
          <Column span={{ md: 6, sm: 12, xs: 12 }}>
            <Controller
              name="is_cost_recoverable_from_other_insurance_comments"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  maxLength={validationSchema.string.maxLength}
                  multiline
                  placeholder="Write details here"
                  showMandatory={is_cost_recoverable_from_other_insurance}
                />
              )}
              rules={{
                maxLength: maxLengthValidation(validationSchema.string.maxLength),
                required: is_cost_recoverable_from_other_insurance ? requiredValidation('More detail') : undefined,
              }}
            />
          </Column>
        </Row>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    minHeight: 400,
    borderColor: colors.lightPurple2,
    flex: 1,
  },
  titleContainer: {
    borderColor: colors.lightPurple2,
    borderBottomWidth: 1,
  },
});
