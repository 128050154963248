import React from 'react';
import { View } from 'react-native';

import { flex1, mb15 } from '~/common/commonStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { H6NsSemiBoldBlack } from '~/components/commonText';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { ScrollableHealthProfileLog } from './ScrollableHealthProfileLog';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { healthProfileLabels } from '~/utils/labels/healthProfileLabels';
import { ActivityIndicator } from 'react-native-paper';
import { colors } from '~/utils/colors';

interface Props {
  backLink: INavigate;
  healthProfileId: number;
  loading?: boolean;
}

export const AuditLogsMobile: React.FC<Props> = ({ backLink, healthProfileId, loading }) => {
  return (
    <PatientsLayout
      title="Health Profile"
      back={{ to: backLink }}
      hideHeaderRight
      flexContent={false}
      scrollContentNode={
        <View style={{ flex: 1, display: 'flex' }}>
          <View style={[mb15, { paddingHorizontal: MOBILE_HORIZONTAL_SPACE }]}>
            <H6NsSemiBoldBlack>{healthProfileLabels.auditLogs}</H6NsSemiBoldBlack>
          </View>
          <View style={flex1}>
            {loading ? (
              <ActivityIndicator size="small" color={colors.info} />
            ) : (
              <ScrollableHealthProfileLog id={healthProfileId} />
            )}
          </View>
        </View>
      }></PatientsLayout>
  );
};
