import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { hasToken } from '~/api/axios/interceptors';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { useLazySubscriptionMeQuery } from '~/redux/api/meApi';
import { subscriptionPlan } from '~/redux/reducers/userDetailsReducer';
import { isClinicVersion, isPharmacyVersion } from '~/utils/buildConfig';

export const checkIsPremiumPlan = (plan: SubscriptionPlanMeResponse) => {
  return (
    plan && [SubscriptionTypeEnum.PREMIUMPATIENT, SubscriptionTypeEnum.PREMIUMDOCTOR].includes(plan?.subscription_type)
  );
};

export function useSubscriptions() {
  const currentPlan = useSelector(subscriptionPlan);

  const [trigger] = useLazySubscriptionMeQuery();

  const getCurrentSubscriptionPlan = async () => {
    try {
      if (isPharmacyVersion() || isClinicVersion()) return;
      if (!(await hasToken())) return;
      const { data } = await trigger();

      return data;
    } catch {}
  };

  useEffect(() => {
    if (!currentPlan) getCurrentSubscriptionPlan();
  }, []);

  const isFreePlan = useMemo(
    () =>
      currentPlan &&
      [SubscriptionTypeEnum.FREEPATIENT, SubscriptionTypeEnum.FREEDOCTOR].includes(currentPlan?.subscription_type),
    [currentPlan]
  );
  const isPremiumPlan = useMemo(() => checkIsPremiumPlan(currentPlan), [currentPlan]);

  return { currentPlan, getCurrentSubscriptionPlan, isPremiumPlan, isFreePlan };
}
