import React from 'react';
import { labels } from '../../utils/labels';
import { Button, TextButton } from '../commonButton';
import { StyleSheet, View } from 'react-native';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  waiting?: boolean;
  disabled?: boolean;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  hideContinue?: boolean;
  hideCancel?: boolean;
}

export const CancelContinueFooterArray = ({
  onCancel,
  onConfirm,
  cancelTitle = labels.cancel,
  confirmTitle = labels.continue,
  waiting,
  hideCancel,
  hideContinue,
  disabled,
  disabledConfirm,
  disabledCancel,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const buttons = [
    hideContinue ? null : (
      <View key="confirmButton" style={isMobile ? null : styles.buttonWidth}>
        <Button label={confirmTitle} funCallback={onConfirm} disabled={waiting || disabled || disabledConfirm} />
      </View>
    ),
    hideCancel ? null : (
      <View key="cancelButton" style={isMobile ? null : styles.buttonWidth}>
        <TextButton
          label={cancelTitle.toUpperCase()}
          funCallback={onCancel}
          disabled={waiting || disabled || disabledCancel}
        />
      </View>
    ),
  ];

  return [
    <View key="buttonContainer" style={isMobile ? styles.containerMobile : styles.container}>
      {buttons.filter(Boolean)}
    </View>,
  ];
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  containerMobile: {
    flexDirection: 'column',
  },
  buttonWidth: {
    minWidth: 120,
  },
});
