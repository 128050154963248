import React, { useMemo, useState } from 'react';
import { View } from 'react-native';

import { ClinicCompletedConsultationAction } from './ClinicCompletedConsultationAction';
import { ClinicRequestDetails } from './ClinicRequestDetails';
import { ClinicRequestDoctorListing } from './ClinicRequestDoctorListing';
import { ClinicRequestsAction } from './ClinicRequestsAction';

import { useClinicRequestHandling } from '~/api/hooks/clinics/ClinicRequestHandlingHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiDoctorCancelAppointment } from '~/api/services/appointments';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { SuccessAlert } from '~/common/commonMethods';
import { flex1, mb20, mt20 } from '~/common/commonStyles';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { ColouredStatusBadge } from '~/components/misc/ColouredStatusBadge';
import { Splitter } from '~/components/misc/Splitter';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { AppointmentStateToName } from '~/constants/appointments/appointmentStateMapping';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { appointmentLabels } from '~/utils/labels/appointments';

const STATUS_COLOR_MAPPING = {
  [ConsultationStateEnum.Cancelled]: {
    color: colors.danger,
    label: AppointmentStateToName[ConsultationStateEnum.Cancelled],
  },
  [ConsultationStateEnum.RequestSubmitted]: {
    color: colors.darkWarning,
    label: AppointmentStateToName[ConsultationStateEnum.RequestSubmitted],
  },

  [ConsultationStateEnum.Scheduled]: {
    color: colors.success,
    label: 'Assigned',
  },
  [ConsultationStateEnum.PendingPayment]: {
    color: colors.success,
    label: 'Assigned',
  },
  [ConsultationStateEnum.Started]: {
    color: colors.purple,
    label: AppointmentStateToName[ConsultationStateEnum.Started],
  },
  [ConsultationStateEnum.Ended]: { color: colors.purple, label: AppointmentStateToName[ConsultationStateEnum.Ended] },
  [ConsultationStateEnum.Submitted]: {
    color: colors.purple,
    label: AppointmentStateToName[ConsultationStateEnum.Submitted],
  },

  [ConsultationStateEnum.RequestIgnored]: {
    color: colors.grey,
    label: AppointmentStateToName[ConsultationStateEnum.RequestIgnored],
  },
  [ConsultationStateEnum.RequestRejected]: {
    color: colors.grey,
    label: AppointmentStateToName[ConsultationStateEnum.RequestRejected],
  },
};

interface Props {
  consultation: ConsultationModel;
  onConsultationChanged?: () => Promise<void>;
}

export const ClinicRequestsAssignComponent: React.FC<Props> = ({ consultation, onConsultationChanged }) => {
  const { isDesktop, isTablet, isMobile } = useBreakpoints();
  const [potentialDoctor, setPotentialDoctor] = useState<AvailableDoctorModel | AccountModel>();
  const { approveRequest, declineRequest, changeDoctor } = useClinicRequestHandling();

  const onConfirmAssigning = async () => {
    try {
      if (!consultation?.id || !potentialDoctor?.id) return;
      if (consultation?.doctor) {
        await changeDoctor(consultation.id, potentialDoctor.id);
      } else {
        await approveRequest(consultation.id, potentialDoctor.id);
      }
      if (onConsultationChanged) await onConsultationChanged();
      setPotentialDoctor(null);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const onDeclineRequest = async () => {
    try {
      if (!consultation?.id) return;
      await declineRequest(consultation.id);
      if (onConsultationChanged) await onConsultationChanged();
      setPotentialDoctor(null);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const { openModal } = useModalManager();
  const cancelAppointment = async (consultationId: number) => {
    return new Promise<void>((resolve) => {
      openModal(ModalName.RejectConsultationReasonModal, {
        consultationId,
        title: appointmentLabels.declineConsultation,
        description: appointmentLabels.declineReason,
        declineApi: apiDoctorCancelAppointment,
        buttonTitle: appointmentLabels.decline,
        onReady: () => {
          resolve();
          SuccessAlert(['The appointment was successfully cancelled']);
        },
      });
    });
  };

  const onCancelAppointment = async () => {
    try {
      if (!consultation?.id) return;
      await cancelAppointment(consultation.id);
      AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED);
      if (onConsultationChanged) await onConsultationChanged();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const canAssign = useMemo(
    () =>
      consultation?.state === ConsultationStateEnum.RequestSubmitted ||
      consultation?.state === ConsultationStateEnum.Scheduled ||
      consultation?.state === ConsultationStateEnum.PendingPayment,
    [consultation?.state]
  );

  const canDownload = useMemo(() => consultation?.state === ConsultationStateEnum.Submitted, [consultation]);

  if (!consultation) return null;
  return (
    <Grid grid={{ noOuterGutter: isDesktop || isTablet, gutters: isDesktop ? 30 : 10 }}>
      <Container style={[flex1]} alignSelf="flex-start">
        <Row style={!isMobile ? flex1 : null}>
          <Column span={{ xs: 12, lg: 8 }} style={[!isMobile ? flex1 : null, isDesktop ? null : mb20]}>
            <DashboardCard
              white
              title="Request"
              childStyle={mt20}
              headerRight={
                <ColouredStatusBadge
                  label={STATUS_COLOR_MAPPING[consultation.state].label}
                  color={STATUS_COLOR_MAPPING[consultation.state].color}
                />
              }>
              <View style={flex1}>
                <ClinicRequestDetails consultation={consultation} />
              </View>
              {canAssign ? (
                <>
                  <Splitter secondaryColor />
                  <ClinicRequestsAction
                    consultation={consultation}
                    potentialDoctor={potentialDoctor}
                    onConfirmDoctor={onConfirmAssigning}
                    onCancelAppointment={onCancelAppointment}
                    onCancelAssigning={async () => setPotentialDoctor(null)}
                    onDeclineRequest={onDeclineRequest}
                  />
                </>
              ) : null}
              {canDownload ? <ClinicCompletedConsultationAction consultation={consultation} /> : null}
            </DashboardCard>
          </Column>
          <Column span={{ xs: 12, lg: 4 }} style={{ maxHeight: '100%' }}>
            <ClinicRequestDoctorListing
              setSelectedDoctor={setPotentialDoctor}
              assignedDoctor={consultation?.doctor}
              selectedDoctor={potentialDoctor}
              disabled={!canAssign}
            />
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};
