import { AppVersionsResponse } from './../../models/common/responses/AppVersionsResponse';
import { deleteReq, get, post, put, setToken } from '../../axios';
import { LoginEmailRequest, LoginRequest } from '../../models/authorisation/requests/LoginRequest';
import { Submit2FAChallengeRequest } from '../../models/authorisation/requests/Submit2FAChallengeRequest';
import { UseRoleRequest } from '../../models/authorisation/requests/UseRoleRequest';
import { AuthorisationResponse } from '../../models/authorisation/responses/AuthorisationResponse';

import { HasWriteAccessRequest } from '~/api/models/access/requests/HasWriteAccessRequest';
import { HasWriteAccessResponse } from '~/api/models/access/responses/HasWriteAccessResponse';
import { IsAllowedAccessResponse } from '~/api/models/access/responses/IsAllowedAccessResponse';
import { UpdateAccountRequest } from '~/api/models/accounts/requests/UpdateAccountRequest';
import { AccountResponse } from '~/api/models/accounts/responses/AccountResponse';
import { BiometricLoginRequest } from '~/api/models/authorisation/requests/BiometricLoginRequest';
import { ChangeEmailRequest } from '~/api/models/authorisation/requests/ChangeEmailRequest';
import { ChangeMobileRequest } from '~/api/models/authorisation/requests/ChangeMobileRequest';
import { ChangePasscodeRequest } from '~/api/models/authorisation/requests/ChangePasscodeRequest';
import { DeviceEnrolmentRequest } from '~/api/models/authorisation/requests/DeviceEnrolmentRequest';
import { RecoveryRequest } from '~/api/models/authorisation/requests/RecoveryRequest';
import { ResetPasscodeRequest } from '~/api/models/authorisation/requests/ResetPasscodeRequest';
import { IdModel } from '~/api/models/common/models/IdModel';

export const apiLogin = (data: LoginRequest) => {
  const xhr = post<AuthorisationResponse>('/login', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};
export const apiLoginEmail = (data: LoginEmailRequest) => {
  const xhr = post<AuthorisationResponse>('/login/email', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiSubmit2FAChallenge = (data: Submit2FAChallengeRequest) => {
  const xhr = post<AuthorisationResponse>('/login/two-factor-challenge', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiAccountDeletion = (data: Submit2FAChallengeRequest) => {
  return deleteReq('/auth/me', data);
};

export const apiUseRole = (data: UseRoleRequest) => {
  const xhr = post<AuthorisationResponse>('auth/role', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiCompleteRoleRegistration = () => {
  return post('/auth/role/complete-registration');
};

export const apiChangePasscode = (params: ChangePasscodeRequest) => {
  return put('/auth/passcode', params);
};

export const apiChangeEmail = (params: ChangeEmailRequest) => {
  return put('/auth/email', params);
};

export const apiChangeMobile = (params: ChangeMobileRequest) => {
  return put('/auth/mobile-number', params);
};

export const apiRecover = (params: RecoveryRequest) => {
  if (params.email) {
    return post('/auth/email-recovery', {
      email: params.email,
    });
  } else if (params.country_code && params.number) {
    return post('/auth/recovery', {
      country_code: params.country_code,
      number: params.number,
    });
  } else {
    return Promise.reject(new Error('Either email or full mobile number need to be provided'));
  }
};

export const apiResetPasscode = (params: ResetPasscodeRequest) => {
  if (params.email) {
    return post<AuthorisationResponse>('/auth/email-reset', {
      email: params.email,
      otp: params.otp,
      passcode: params.passcode,
    });
  } else if (params.country_code && params.number) {
    return post<AuthorisationResponse>('/auth/reset', {
      country_code: params.country_code,
      number: params.number,
      otp: params.otp,
      passcode: params.passcode,
    });
  } else {
    return Promise.reject(new Error('Either email or full mobile number need to be provided'));
  }
};

export const apiGetIsAllowedAccess = () => {
  return get<IsAllowedAccessResponse>('/is-allowed-access');
};

export const apiGetAppVersion = () => {
  return get<AppVersionsResponse>('/app-latest-versions');
};

export const apiEnrollDevice = (params: DeviceEnrolmentRequest) => {
  return post('/auth/device-enrolments', params);
};

export const apiUnenrollDevice = (params: IdModel<string>) => {
  return deleteReq(`/auth/device-enrolments/${params.id}`);
};

export const apiWriteAccess = (params: HasWriteAccessRequest) => {
  return get<HasWriteAccessResponse>('/has-write-access', params);
};

export const apiBiometricLogin = (data: BiometricLoginRequest) => {
  const xhr = post<AuthorisationResponse>('/auth/biometric-login', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiUpdateMyAccount = (params: UpdateAccountRequest) => {
  return put<AccountResponse>('/auth/me/account', params);
};
