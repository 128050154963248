import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';

import { ExpandableReceipt } from './ExpandableReceipt';

import { mr10, mt10, mt15, p15 } from '~/common/commonStyles';
import { H5TtmRegularInfo, H5TtmSemiBoldBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { RoundedAddIcon } from '~/components/svgImages';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  padded?: boolean;
}
export const AmountPaidForm: React.FC<Props> = ({ padded }) => {
  const { isMobile } = useBreakpoints();
  const { insuranceClaimsFieldArray, removeDocument, totalClaimedAmount, consultationFee, digimedFeesTotal } =
    useInsuranceClaimFormContext();

  const [expandedId, setExpandedId] = useState<string>(
    insuranceClaimsFieldArray.fields[insuranceClaimsFieldArray.fields.length - 1]?.fieldId
  );

  useEffect(() => {
    setExpandedId(insuranceClaimsFieldArray.fields[insuranceClaimsFieldArray.fields.length - 1]?.fieldId);
  }, [insuranceClaimsFieldArray.fields?.length]);

  return (
    <View style={[isMobile ? null : styles.container, padded ? p15 : null]}>
      <H6NsSemiBoldBlack>Add another Expense or Supporting Document to your claim</H6NsSemiBoldBlack>

      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
        <H6NsRegularBlack>Consultation fee</H6NsRegularBlack>
        <H6NsRegularBlack>{consultationFee?.formatted_amount ?? '€0.00'}</H6NsRegularBlack>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
        <H6NsRegularBlack>Digimed fees</H6NsRegularBlack>
        <H6NsRegularBlack>{digimedFeesTotal ?? '€0.00'}</H6NsRegularBlack>
      </View>
      <Splitter />
      <View>
        <H6NsRegularBlack>
          Your insurance company will need to see all supporting documentation in order to assess your claim.
        </H6NsRegularBlack>
        <H6NsRegularBlack style={mt10}>
          Here, you can upload all receipts for expenses incurred relating to this claim, as well as diagnostic test
          results (example Blood Tests, X-Ray, ECG, Ultrasound, Mammogram, Bone Density Scan), medical reports, referral
          notes and other similar documentation that will help to support your claim.
        </H6NsRegularBlack>
        <H6NsRegularBlack style={mt10}>
          These documents will be attached to your claim form so that you may send a complete file to your insurance
          company.
        </H6NsRegularBlack>
      </View>

      <List.AccordionGroup
        expandedId={expandedId}
        onAccordionPress={(id) => {
          if (expandedId === id) {
            setExpandedId(null);
          } else {
            setExpandedId(`${id}`);
          }
        }}>
        {insuranceClaimsFieldArray.fields.map((field, index) => {
          return (
            <ExpandableReceipt
              fieldId={field.fieldId}
              index={index}
              key={field.fieldId}
              expanded={expandedId === field.fieldId}
              onRemove={() => removeDocument(index)}
            />
          );
        })}
      </List.AccordionGroup>

      <View style={{ display: 'flex', alignItems: 'flex-start', paddingHorizontal: 16 }}>
        <TouchableOpacity
          style={{ flexDirection: 'row', ...mt15 }}
          onPress={() => {
            insuranceClaimsFieldArray.append({ amount: null, currency: 'EUR', description: '', type: undefined });
          }}>
          <RoundedAddIcon height={20} width={20} color={colors.info} />
          <View style={mr10} />
          <H5TtmRegularInfo>
            {insuranceClaimsFieldArray.fields.length ? ' Add another document' : ' Add document'}
          </H5TtmRegularInfo>
        </TouchableOpacity>
      </View>
      <View style={mt15} />
      <H5TtmSemiBoldBlack>{`Total Claimed Amount: €${totalClaimedAmount?.toFixed(2)}`}</H5TtmSemiBoldBlack>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    minHeight: 400,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    flex: 1,
  },
  titleContainer: {
    borderBottomWidth: 1,
    borderColor: colors.lightPurple2,
  },
});
