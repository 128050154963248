import { activateKeepAwakeAsync, deactivateKeepAwake } from 'expo-keep-awake';
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { OTConnectionState, OTVideoState } from '~/integrations/openTok/types/OTConnectionState';
import { OTPublisherEvents, OTSessionEvents, OTSubscriberEvents } from '~/integrations/openTok/types/OTEvents';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isNative } from '~/utils/buildConfig';

export const useConnectionStateHook = () => {
  const [joinedCall, setJoinedCall] = useState(false);
  const [connectionState, setConnectionState] = useState<OTConnectionState>(OTConnectionState.Initial);
  const publisherConnectionsRef = useRef<string[]>([]);
  const subscriberConnectionsRef = useRef<string[]>([]);
  const { showSnackbar } = useSnackbarManager();
  const [subscriberConnectionState, setSubscriberConnectionState] = useState<OTConnectionState>(
    OTConnectionState.Initial
  );
  const [subscriberVideoState, setSubscriberVideoState] = useState<OTVideoState>(OTVideoState.Disabled);
  const [publisherVideoState, setPublisherVideoState] = useState<OTVideoState>(OTVideoState.Disabled);
  const [publisherStreamStatus, setPublisherStreamCompleted] = useState(false);
  const [publisherStreamId, setPublisherStreamId] = useState<number | null>(null);
  const { userDetails } = useUserDetails({ allowStale: true });

  const updateJoinedCall = async (join: boolean) => {
    setJoinedCall(join);

    // Keep device awake during the call
    try {
      if (join) {
        setSubscriberConnectionState(OTConnectionState.Initial);

        await activateKeepAwakeAsync('call');
      } else {
        setPublisherStreamCompleted(false);

        await deactivateKeepAwake('call');
      }
    } catch {}
  };

  const transitionConnectionState = (state: OTConnectionState) => {
    setConnectionState(state);
    if (state === OTConnectionState.Disconnected && joinedCall) {
      updateJoinedCall(false);
    }
  };

  const handleConsultationEnded = useCallback(() => {
    setSubscriberConnectionState(OTConnectionState.Disconnected);
    setJoinedCall(false);
    setPublisherStreamCompleted(false);
  }, []);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.PATIENT_APPOINTMENT_ENDED, handleConsultationEnded);
    return removeListener;
  }, []);

  const handleNewConnection = (connectionId: string, connectionUserId: number) => {
    if (userDetails.id === connectionUserId) {
      if (!publisherConnectionsRef.current.includes(connectionId)) {
        publisherConnectionsRef.current.push(connectionId);
        if (!subscriberConnectionsRef.current.length) {
          setSubscriberConnectionState(OTConnectionState.Initial);
        }
        setPublisherStreamId(connectionUserId);
      }
      if (connectionUserId && connectionId) {
        if (isNative()) setPublisherStreamCompleted(true);
      }
    }
  };

  const connectionCreated: OTSessionEvents['connectionCreated'] = (ev) => {
    const connection = 'connectionId' in ev ? ev : ev.connection;
    const connectionUserId = Number(connection.data);
    handleNewConnection(connection.connectionId, connectionUserId);

    if (userDetails.id !== connectionUserId) {
      if (!subscriberConnectionsRef.current.includes(connection.connectionId)) {
        subscriberConnectionsRef.current.push(connection.connectionId);
      }
      setSubscriberConnectionState(OTConnectionState.Connected);
    }
  };

  const sessionConnected: OTSessionEvents['sessionConnected'] = (ev) => {
    transitionConnectionState(OTConnectionState.Connected);
    const connection = 'connection' in ev ? ev.connection : ev.target.connection;
    const connectionUserId = Number(connection.data);
    handleNewConnection(connection.connectionId, connectionUserId);
  };

  const connectionDestroyed = (ev) => {
    const connection = 'connectionId' in ev ? ev : ev.connection;
    const connectionUserId = Number(connection.data);

    if (userDetails.id === connectionUserId) {
      const index = publisherConnectionsRef.current.indexOf(connection.connectionId);
      if (index > -1) {
        const copiedArray = [...publisherConnectionsRef.current];
        copiedArray.splice(index, 1);
        publisherConnectionsRef.current = copiedArray;
        //match the publisherId with destroyedId for multiple connected devices
        if (publisherStreamId === connectionUserId) {
          setPublisherStreamId(null);
        }
      }
    } else {
      const index = subscriberConnectionsRef.current.indexOf(connection.connectionId);
      if (index > -1) {
        const copiedArray = [...subscriberConnectionsRef.current];
        copiedArray.splice(index, 1);
        subscriberConnectionsRef.current = copiedArray;
      }
      if (!subscriberConnectionsRef.current.length) setSubscriberConnectionState(OTConnectionState.Disconnected);
      setSubscriberConnectionState(OTConnectionState.Disconnected);
    }
  };

  const sessionEventHandlers: OTSessionEvents = {
    connectionCreated,
    connectionDestroyed,
    sessionReconnecting: () => {
      transitionConnectionState(OTConnectionState.Reconnecting);
    },
    sessionConnected,
    sessionDisconnected: () => transitionConnectionState(OTConnectionState.Disconnected),
    sessionReconnected: () => transitionConnectionState(OTConnectionState.Connected),
  };

  const subscriberEventHandlers: OTSubscriberEvents = {
    connected: () => {
      if (subscriberConnectionState === OTConnectionState.Reconnecting)
        setSubscriberConnectionState(OTConnectionState.Connected);
    },
    disconnected: () => {
      setSubscriberConnectionState(OTConnectionState.Reconnecting);
    },
    videoDisableWarning: () => {
      setSubscriberVideoState(OTVideoState.Warning);
    },
    videoDisableWarningLifted: () => {
      setSubscriberVideoState(OTVideoState.Enabled);
    },
    videoDisabled: (ev) => {
      switch (ev.reason) {
        case 'codecNotSupported':
          setSubscriberVideoState(OTVideoState.NotSupported);
          break;
        case 'quality':
          setSubscriberVideoState(OTVideoState.PoorQuality);
          break;
        case 'publishVideo':
        case 'subscribeToVideo':
        default:
          setSubscriberVideoState(OTVideoState.Disabled);
      }
    },
    videoEnabled: () => setSubscriberVideoState(OTVideoState.Enabled),
  };

  const publisherEventsHandler: OTPublisherEvents = useMemo(() => {
    const handlers = {
      videoDisableWarning: () => {
        setSubscriberVideoState(OTVideoState.Warning);
      },
      videoDisableWarningLifted: () => {
        setPublisherVideoState(OTVideoState.Enabled);
      },
      videoDisabled: (ev) => {
        switch (ev.reason) {
          case 'codecNotSupported':
            setPublisherVideoState(OTVideoState.NotSupported);
            break;
          case 'quality':
            setPublisherVideoState(OTVideoState.PoorQuality);
            break;
          case 'publishVideo':
          case 'subscribeToVideo':
          default:
            setPublisherVideoState(OTVideoState.Disabled);
        }
      },
      videoEnabled: () => setPublisherVideoState(OTVideoState.Enabled),
    };
    if (!isNative()) {
      handlers['mediaStopped'] = (ev) => {
        showSnackbar(
          'Seems there was an issue with your video or audio. Try refreshing your page and join the call again.',
          {
            isError: true,
            noTimer: true,
          }
        );
      };
    }
    return handlers;
  }, []);

  const onPublisherStreamCompleted = () => {
    setPublisherStreamCompleted(true);
  };

  return {
    connectionState,
    joinedCall,
    subscriberConnectionState,
    subscriberVideoState,
    publisherVideoState,
    sessionEventHandlers,
    subscriberEventHandlers,
    publisherEventsHandler,
    transitionConnectionState,
    setJoinedCall: updateJoinedCall,
    publisherStreamStatus,
    onPublisherStreamCompleted,
  };
};
