import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { InsurancePolicyDetails } from '../../components/InsurancePolicyDetails';
import { useInsuranceDetails } from '../../hooks/useInsuranceDetails';

import { mb10, pb15 } from '~/common/commonStyles';
import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

export const ClaimDescriptionMobile: React.FC = () => {
  const { loading, nextPage } = useInsuranceClaimFormContext();
  const { insuranceDetails } = useInsuranceDetails();
  const { navigate } = useNavigation<NavType>();

  const buttons = CancelContinueFooterArray({
    waiting: loading,
    onCancel: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
    onConfirm: nextPage,
  });

  return (
    <WizardLayout buttons={buttons} title="Consultation Details" hideRequired wide>
      <Grid grid={{ gutters: 16, noOuterGutter: false }}>
        <Container alignSelf="center" style={pb15}>
          <Row style={mb10}>
            <Column>
              <Row flex justifyContent="center">
                <InsurancePolicyDetails insuranceDetails={insuranceDetails} />
              </Row>
            </Column>
          </Row>
        </Container>
      </Grid>
    </WizardLayout>
  );
};
