import { Route, useNavigation } from '@react-navigation/native';
import React from 'react';
import { mv20, ph15 } from '~/common/commonStyles';
import { ShadowButton } from '~/components/commonButton';

import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { PATIENT_HEALTH_PROFILE_PAGES } from '~/constants/patients/HealthProfileMobilePages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
//MOBILE: PATIENT> HEALTH PROFILE
interface Props {
  route?: Route<string, { id: string }>;
}

export const PatientHealthProfileMobile: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;
  const navigation = useNavigation<NavType>();

  const viewAuditLogs = () => {
    navigation.navigate(screenName.HealthProfileAuditLogs, { id: patientId });
  };

  return (
    <PatientsLayout
      title="Health Profile"
      hideHeaderRight
      style={{ backgroundColor: colors.lightPurple }}
      flexContent={false}
      back={{ to: { screen: screenName.PatientDetails, params: route.params } }}
      hideGradient
      scrollStyling={{ backgroundColor: colors.lightPurple }}>
      <MobileMenuPageComponent menu={PATIENT_HEALTH_PROFILE_PAGES} params={{ id: patientId } as never} />
      <ShadowButton
        label={labels.viewAuditLogs}
        style={[ph15, mv20, { alignSelf: 'flex-start' }]}
        onPress={viewAuditLogs}
      />
    </PatientsLayout>
  );
};
