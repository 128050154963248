import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { colors } from '~/utils/colors';

export const STATUS_COLOR_MAPPING = {
  [ConsultationStateEnum.Ended]: { color: colors.warning, label: 'Pending' },
  [ConsultationStateEnum.Submitted]: { color: colors.success, label: 'Completed' },
};

export const BADGE_COLOR_MAPPING = {
  [ConsultationStateEnum.Ended]: { color: colors.darkWarning, label: 'Pending submission' },
  [ConsultationStateEnum.Submitted]: { color: colors.purple, label: 'Completed' },
  [ConsultationStateEnum.Scheduled]: { color: colors.info, label: 'Scheduled' },
  [ConsultationStateEnum.PendingPayment]: { color: colors.info, label: 'Scheduled' },
};
