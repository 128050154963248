import { Feather, SimpleLineIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useNotifications } from './Notifications';
import { useConsultationHelper } from './helpers/HelperConsultation';

import { DateTimeType } from '~/api/models/common/types/DateType';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import {
  ConsultationActionableNotification,
  ConsultationRescheduleActionableNotification,
  ConsultationWithValidUntilActionableNotification,
  ModelActionableNotification,
  NotificationModel,
  PrescriptionActionableNotification,
} from '~/api/models/notifications/models/NotificationModel';
import { apiGetRescheduleRequest } from '~/api/services/consultations/request';
import { NotificationOptionInterface } from '~/components/doctor/notifications/models/NotificationOptionInterface';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { EyeIcon, Settings, Success } from '~/components/svgImages';
import { NotificationCodeEnum } from '~/integrations/pushNotifications/enums/NotificationCodeEnum';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { sameOrAfter } from '~/utils/dateAndTime';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { screenName } from '~/utils/screenName';
import { apiGetPrescription } from '~/api/services/consultations/prescriptions';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ConsultationRescheduleDetailsModel } from '~/api/models/appointments/models/ConsultationRescheduleDetailsModel';
import { useSubscriptions } from '../subscriptions/SubscriptionHook';

interface Props {
  createdAt?: DateTimeType;
  notificationData?: NotificationModel['data'];
}

export const useNotificationActions = ({ createdAt, notificationData }: Props) => {
  const { markNotificationAsRead } = useNotifications();
  const { navigate } = useNavigation<NavType>();
  const [actions, setActions] = useState<NotificationOptionInterface[]>();

  const { showDetails } = useShowAppointment();
  const { openModal } = useModalManager();
  const { getConsultation } = useConsultationHelper();
  const { showSnackbar } = useSnackbarManager();
  const { isPremiumPlan } = useSubscriptions();

  const getConsultationErrorMessage = (consultationId: number, state: ConsultationStateEnum) => {
    switch (state) {
      case ConsultationStateEnum.RequestRejected:
        return `Consultation request #${consultationId} was rejected.`;
      case ConsultationStateEnum.RequestIgnored:
        return `Consultation request #${consultationId} was not handled on time.`;
      case ConsultationStateEnum.Started:
        return `Consultation #${consultationId} has already started.`;
      case ConsultationStateEnum.Ended:
      case ConsultationStateEnum.Submitted:
        return `Consultation #${consultationId} has already ended.`;

      default:
        return `Consultation #${consultationId} was not found. The request may have expired`;
    }
  };

  const openApprovalRequestModal = async (consultationId: number) => {
    const consultation = await getConsultation(consultationId);

    if (consultation?.state !== ConsultationStateEnum.RequestSubmitted) {
      showSnackbar(getConsultationErrorMessage(consultationId, consultation?.state), { isError: true });
    } else {
      openModal(ModalName.ApprovalRequest, {
        consultation,
      });
    }
  };

  const openRescheduleRequest = async (consultationId: number, consultationRescheduleRequest: number) => {
    const rescheduleRequest: ConsultationRescheduleDetailsModel = await apiGetRescheduleRequest({
      consultationId,
      consultationRequestId: consultationRescheduleRequest,
    })
      .then((response) => response.data)
      .catch(() => undefined);

    if (rescheduleRequest?.state !== 'pending' || rescheduleRequest?.consultation.state !== 'scheduled') {
      showSnackbar('The request you are trying to get may have already been handled or expired', { isError: true });
    } else {
      openModal(ModalName.PatientAppointmentRescheduleModal, {
        consultationRescheduleRequest: rescheduleRequest,
      });
    }
  };

  const openDoctorConsultationReschedule = async (consultationId: number) => {
    try {
      const consultation = await getConsultation(consultationId);

      if (
        consultation?.state === ConsultationStateEnum.Scheduled ||
        consultation?.state === ConsultationStateEnum.PendingPayment
      ) {
        openModal(ModalName.AppointmentNotification, {
          consultation,
          consultationId,
        });
      } else {
        showSnackbar(getConsultationErrorMessage(consultationId, consultation?.state), { isError: true });
      }
    } catch {
      showSnackbar(getConsultationErrorMessage(consultationId, undefined), { isError: true });
    }
  };

  const openPrescription = async (prescriptionId: number) => {
    try {
      const response = await apiGetPrescription({ prescriptionId });
      const prescription = response.data;

      openModal(ModalName.PrescriptionDetailsModal, {
        prescription,
      });
    } catch (e) {
      if (!isPremiumPlan) {
        openModal(ModalName.PaywallModal, {
          title: 'Upgrade your plan to view prescriptions',
        });
      } else {
        ErrorAlert(e);
      }
    }
  };

  const showConsultationDetails = (consultationId: number): NotificationOptionInterface =>
    consultationId
      ? {
          title: 'Show consultation',
          icon: (color) => <EyeIcon height={20} width={20} color={color} />,
          action: () => {
            showDetails({ consultationId });
          },
        }
      : null;

  const menuCustomActions = (): NotificationOptionInterface[] | null => {
    switch (notificationData?.notification_code) {
      case NotificationCodeEnum.CONSULTATION_STARTING: {
        const consultationNotification = notificationData as ConsultationWithValidUntilActionableNotification;
        if (isDoctorVersion()) {
          return [
            {
              title: 'Reschedule',
              icon: (color) => <Feather name="clock" size={20} color={color} />,
              action: () => {
                openDoctorConsultationReschedule(consultationNotification.consultation_id);
              },
              disabled: () => {
                if (!consultationNotification.valid_until) return false;
                const now = moment();
                const validUntil = moment(consultationNotification.valid_until);
                const isSameOrAfter = sameOrAfter(now, validUntil);
                return isSameOrAfter;
              },
            },
            showConsultationDetails(consultationNotification?.consultation_id),
          ];
        } else {
          return [showConsultationDetails(consultationNotification?.consultation_id)];
        }
      }
      case NotificationCodeEnum.CONSULTATION_RESCHEDULE_REQUEST_ACCEPTED:
      case NotificationCodeEnum.CONSULTATION_RESCHEDULE_REQUEST_REJECTED:
      case NotificationCodeEnum.CONSULTATION_NOT_SUBMITTED:
      case NotificationCodeEnum.UPCOMING_HOME_VISIT_CONSULTATION:
        return [showConsultationDetails((notificationData as ConsultationActionableNotification)?.consultation_id)];
      case NotificationCodeEnum.CONSULTATION_REQUEST_SUBMITTED:
        if ('consultation_id' in notificationData) {
          return [
            {
              title: 'Request details',
              icon: (color) => <Feather name="info" size={20} color={color} />,
              action: () => {
                openApprovalRequestModal((notificationData as ConsultationActionableNotification).consultation_id);
              },
            },
            showConsultationDetails((notificationData as ConsultationActionableNotification).consultation_id),
          ];
        }
        break;
      case NotificationCodeEnum.SIX_MONTHS_AFTER_CONSULTATION:
        return [showConsultationDetails((notificationData as ModelActionableNotification)?.model_id)];
      case NotificationCodeEnum.CONSULTATION_RESCHEDULE_REQUEST:
        if ('consultation_id' in notificationData && 'consultation_reschedule_request' in notificationData) {
          const rescheduleNotificationData = notificationData as ConsultationRescheduleActionableNotification;
          return [
            {
              title: 'Check request',
              icon: (color) => <SimpleLineIcons name="clock" size={20} color={color} />,
              action: () => {
                openRescheduleRequest(
                  rescheduleNotificationData.consultation_id,
                  rescheduleNotificationData.consultation_reschedule_request
                );
              },
              disabled: () =>
                !rescheduleNotificationData.valid_until ||
                sameOrAfter(new Date(), moment(rescheduleNotificationData.valid_until)),
            },
            showConsultationDetails(rescheduleNotificationData.consultation_id),
          ];
        }
        break;
      case NotificationCodeEnum.PRESCRIPTION_REMINDER:
      case NotificationCodeEnum.PRESCRIPTION_REQUEST_REJECTED:
      case NotificationCodeEnum.PRESCRIPTION_REQUEST_APPROVED:
      default: {
        if (!notificationData) return [];

        const options: NotificationOptionInterface[] = [];
        if ('prescription_id' in notificationData) {
          options.push({
            title: 'Show prescription',
            icon: (color) => <EyeIcon height={20} width={20} color={color} />,
            action: () => {
              openPrescription(notificationData.prescription_id);
            },
          });
        }
        if ('consultation_id' in notificationData) {
          options.push(
            showConsultationDetails((notificationData as ConsultationActionableNotification).consultation_id)
          );
        }
        return options;
      }
    }
  };

  const menuActions = () => {
    const customActions = menuCustomActions() || [];
    const allActions = [
      ...customActions,
      {
        title: 'Mark as Read',
        icon: (color) => <Success height={20} width={20} color={color} />,
        action: markNotificationAsRead,
        disabled: (notification: NotificationModel) => !!notification.read_at,
      },
      {
        title: 'Settings',
        icon: (color) => <Settings height={20} width={20} color={color} />,
        action: () => {
          navigate(screenName.Settings, { screen: screenName.SettingsNotifications });
        },
      },
    ];

    setActions(allActions.filter((item) => !!item));
  };

  useEffect(() => {
    menuActions();
  }, [notificationData]);

  return { actions };
};
