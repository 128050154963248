import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';
import { AuthMeResponse } from '~/api/models/authorisation/responses/AuthMeResponse';
import {
  UPDATE_FEATURE_ACCESS,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_VERIFICATION_STATUS,
  USER_DETAILS,
} from '../reducers/userDetailsReducer';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { FeatureAccessResponse } from '~/api/models/subscriptions/responses/FeatureAccessResponse';
import { AuthVerificationStatusResponse } from '~/api/models/authorisation/responses/AuthVerificationStatusResponse';

export const meApi = createApi({
  reducerPath: 'meApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    authMe: builder.query<AuthMeResponse, void>({
      query: () => ({
        url: 'auth/me',
        method: 'get',
        data: undefined,
        params: undefined,
        headers: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(USER_DETAILS(data));
        } catch {}
      },
    }),
    subscriptionMe: builder.query<SubscriptionPlanMeResponse, void>({
      query: () => ({
        url: 'me/subscription',
        method: 'get',
        params: undefined,
        headers: undefined,
        data: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(UPDATE_SUBSCRIPTION_PLAN(data));
        } catch {}
      },
    }),
    featureAccessMe: builder.query<FeatureAccessResponse, void>({
      query: () => ({
        url: 'me/feature-access',
        method: 'get',
        data: undefined,
        params: undefined,
        headers: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(UPDATE_FEATURE_ACCESS(data.features));
        } catch {}
      },
    }),
    verificationStatus: builder.query<AuthVerificationStatusResponse, void>({
      query: () => ({
        url: '/auth/verification-status',
        method: 'get',
        data: undefined,
        params: undefined,
        headers: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(UPDATE_VERIFICATION_STATUS(data));
        } catch {}
      },
    }),
  }),
});

export const {
  useLazyAuthMeQuery,
  useLazySubscriptionMeQuery,
  useLazyFeatureAccessMeQuery,
  useLazyVerificationStatusQuery,
} = meApi;
