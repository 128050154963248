import { Feather } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Menu } from 'react-native-paper';

import { CallIconButton } from './CallIconButton';

import { flexRow, justifyCenter } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H6NsRegularTheme } from '~/components/commonText';
import { webMenuStyles } from '~/components/doctor/notifications/DotMenuCommonStyles';
import { CallSettingsModal } from '~/components/modals/CallSettingsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { Maximize, MoreIcon, Settings } from '~/components/svgImages';
import {
  addFullScreenChangeListener,
  checkIsFullScreen,
  toggleFullScreen,
} from '~/integrations/fullscreen/UseFullScreen';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  disabled?: boolean;
  onChangeSelectedVideoDevice: (device: string) => void;
  onChangeSelectedAudioDevice: (device: string) => void;
}

export const CallMenuButton: React.FC<Props> = ({
  disabled,
  onChangeSelectedAudioDevice,
  onChangeSelectedVideoDevice,
}) => {
  const [visible, setVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { isMobile } = useBreakpoints();
  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    if (isWeb()) {
      registerModal(ModalName.CallSettings, CallSettingsModal);

      const unsubscribe = addFullScreenChangeListener(() => {
        setIsFullScreen(checkIsFullScreen());
      });

      return unsubscribe;
    }
  }, []);

  const button = ({ title, icon }) => {
    return (
      <View style={flexRow} key={title}>
        <View style={[justifyCenter, { marginRight: 15 }]}>{icon}</View>
        <View>
          <H6NsRegularTheme key={title}>{title}</H6NsRegularTheme>
        </View>
      </View>
    );
  };

  const handleFullScreen = () => {
    const res = toggleFullScreen('digimed-call');
    setIsFullScreen(res.isFullScreen);
    setVisible(false);
  };

  const openSettings = () => {
    openModal(ModalName.CallSettings, {
      setSelectedVideoDevice: onChangeSelectedVideoDevice,
      setSelectedAudioInputDevice: onChangeSelectedAudioDevice,
    });
  };

  if (!isWeb()) return;

  if (isMobile) {
    return (
      <CallIconButton
        value={false}
        onChange={openSettings}
        backgroundColor={colors.grey}
        iconOn={(color) => <Settings width={30} height={30} color={color} />}
        iconOff={(color) => <Settings width={30} height={30} color={color} />}
      />
    );
  }

  if (isFullScreen) {
    return (
      <CallIconButton
        value
        onChange={handleFullScreen}
        backgroundColor={colors.white}
        iconOn={(color) => <Feather name="minimize" size={30} color={colors.purple} />}
        iconOff={(color) => <Feather name="minimize" size={30} color={colors.purple} />}
      />
    );
  }

  return (
    <IconButton
      onPress={() => setVisible(true)}
      transparent={isMobile}
      disabled={disabled}
      style={[
        { marginLeft: 10, paddingVertical: 10, paddingHorizontal: 13 },
        isMobile ? { backgroundColor: colors.grey } : null,
      ]}
      hoverStyle={{ borderColor: colors.lightPurple, backgroundColor: colors.lightPurple }}>
      <Menu
        visible={visible}
        onDismiss={() => {
          setVisible(false);
        }}
        anchor={<MoreIcon height={20} width={20} color={isMobile ? colors.white : colors.purple} />}
        style={webMenuStyles.menuStyle}
        contentStyle={{ borderRadius: 6 }}>
        <Menu.Item
          title={button({
            title: 'Full screen',
            icon: <Maximize width={30} height={25} color={colors.purple} />,
          })}
          onPress={handleFullScreen}
        />

        <Menu.Item
          title={button({
            title: 'Settings',
            icon: <Settings width={30} height={25} color={colors.purple} />,
          })}
          onPress={() => {
            openSettings();
            setVisible(false);
          }}
        />
      </Menu>
    </IconButton>
  );
};
