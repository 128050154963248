import React, { useEffect, useState } from 'react';
import { View, TouchableWithoutFeedback } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { ModalAlertTypeEnum } from './ModalEnums';
import { BaseModalProps } from './interfaces/ModalProps';
import { mt20, txtCenter } from '../../common/commonStyles';
import { labels } from '../../utils/labels';
import { Button, TextButton } from '../commonButton';
import { ExtraSmallNsRegularPlaceholder, H2TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../commonText';
import { InProgress, Success, Error, AlertCircle } from '../svgImages';

import { OptionalModalOverlayProperties } from '~/interfaces/overlayInterface';
import { HIDE_SUCCESS_ERROR_POPUP } from '~/redux/reducers/overlayReducer';
import { useAppDispatch } from '~/redux/store';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

interface Props extends BaseModalProps {
  popupFlag: boolean;
  popupMessage: string[];
  popupType: ModalAlertTypeEnum;
  popupTitle: string;
  popupButtonText?: string;
  popupBlockButtons?: boolean;
  popupOptionalFields?: OptionalModalOverlayProperties;
}
export const SuccessOrErrorPopup: React.FC<Props> = ({
  popupFlag,
  popupMessage,
  popupType,
  popupTitle,
  popupButtonText,
  popupBlockButtons,
  popupOptionalFields,
  onHide,
}) => {
  const [visible, setVisible] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (popupFlag !== visible) {
      setVisible(popupFlag);
    }
  }, [popupFlag]);

  const hide = () => {
    dispatch(HIDE_SUCCESS_ERROR_POPUP());
    if (onHide) onHide();
  };

  const primaryAction = async () => {
    if (popupOptionalFields?.popupFunction) {
      setButtonsDisabled(true);
      const res = await Promise.resolve(popupOptionalFields.popupFunction());
      setButtonsDisabled(false);
      if (typeof res === 'object') {
        if (res.preventHide) return;
      }
    }
    hide();
  };
  const secondaryAction = () => {
    if (popupOptionalFields?.secondaryButtonFunction) {
      popupOptionalFields.secondaryButtonFunction();
    }
    if (!popupOptionalFields?.preventHideOnSecondary) hide();
  };

  const additionalAction = () => {
    if (popupOptionalFields?.additionalFunction) {
      popupOptionalFields.additionalFunction();
    }
    if (!popupOptionalFields?.preventHideOnSecondary) hide();
  };

  if (!visible) return null;

  const buttons = [
    !popupBlockButtons ? (
      <Button
        funCallback={primaryAction}
        label={popupButtonText ? popupButtonText : labels.ok}
        disabled={buttonsDisabled}
      />
    ) : null,
    !popupBlockButtons && popupOptionalFields?.secondaryButtonTitle && secondaryAction ? (
      <TextButton
        label={popupOptionalFields?.secondaryButtonTitle}
        funCallback={secondaryAction}
        disabled={buttonsDisabled}
      />
    ) : null,
    !popupBlockButtons && popupOptionalFields?.additionalTitle && additionalAction ? (
      <TextButton
        label={popupOptionalFields?.additionalTitle}
        funCallback={additionalAction}
        disabled={buttonsDisabled}
      />
    ) : null,
  ];
  return (
    <ModalContainer
      hideOnBackground={popupOptionalFields?.popupHideOnBackground ?? true}
      onHide={primaryAction}
      centerModal={popupOptionalFields?.centerModal}
      buttons={buttons}>
      <View>
        <View style={{ alignSelf: 'center', marginBottom: 10 }}>
          {popupType === ModalAlertTypeEnum.IN_PROGRESS ? (
            <InProgress height={55} width={55} />
          ) : popupType === ModalAlertTypeEnum.SUCCESS ? (
            <Success height={55} width={55} />
          ) : popupType === ModalAlertTypeEnum.ERROR ? (
            <Error height={55} width={55} />
          ) : popupType === ModalAlertTypeEnum.WARNING ? (
            <AlertCircle height={55} width={55} />
          ) : null}
        </View>
        {popupType !== ModalAlertTypeEnum.NONE || popupTitle ? (
          <H2TtmSemiBoldBlack style={[txtCenter, { marginTop: 10, marginBottom: 20 }]}>
            {popupTitle
              ? popupTitle
              : popupType === ModalAlertTypeEnum.IN_PROGRESS
              ? 'In-Progress'
              : popupType === ModalAlertTypeEnum.SUCCESS
              ? 'Success'
              : popupType === ModalAlertTypeEnum.ERROR
              ? 'Error'
              : ''}
          </H2TtmSemiBoldBlack>
        ) : null}

        {typeof popupMessage === 'object' && popupMessage?.length > 0 ? (
          popupMessage
            .filter((item) => !isNullOrUndefined(item))
            .map((msg: string, index: number) => {
              return (
                <TouchableWithoutFeedback key={index} touchSoundDisabled>
                  <H6NsRegularSecondaryBlack
                    key={index}
                    style={[txtCenter, index > 0 ? { marginTop: 5 } : null]}
                    suppressHighlighting>
                    {msg}
                  </H6NsRegularSecondaryBlack>
                </TouchableWithoutFeedback>
              );
            })
        ) : typeof popupMessage === 'string' ? (
          <H6NsRegularSecondaryBlack style={txtCenter}>{popupMessage}</H6NsRegularSecondaryBlack>
        ) : typeof popupMessage !== 'object' && typeof popupMessage !== 'string' ? (
          <H6NsRegularSecondaryBlack style={txtCenter}>Something went wrong.</H6NsRegularSecondaryBlack>
        ) : null}
        {popupOptionalFields?.errorCode ? (
          <ExtraSmallNsRegularPlaceholder style={mt20}>
            Code: {popupOptionalFields.errorCode}
          </ExtraSmallNsRegularPlaceholder>
        ) : null}
      </View>
    </ModalContainer>
  );
};
