import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { HealthProfileAuditLog } from './HealthProfileAuditLog';
import { PatientHealthMetrics } from './PatientHealthMetrics';
import { PatientHistory } from './PatientHistory';
import { PatientProsthesis } from './PatientProsthesis';
import { PatientLifestyle } from './lifestyle/PatientLifestyle';
import { flex1, mb10, mh5, mt15, mv10, mv15, ph15 } from '../../../../common/commonStyles';
import { separator } from '../../../../styles/commonStyling';
import { Column, Container, Grid, Row } from '../../../../theme/components/grid';
import { labels } from '../../../../utils/labels';
import { CancelContinueFooter } from '../../../buttons/CancelContinueFooter';
import { ShadowButton, TextButton } from '../../../commonButton';
import { H3TtmSemiBoldBlack, SmallNsRegularBlack } from '../../../commonText';
import { PatientsLayout } from '../PatientsLayout';

import { INavigate } from '~/common/types/navigation/navigate';
import { GridLoadingWrapper } from '~/components/loading/GridLoadingWrapper';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { HealthProfileMetricsProvider } from '~/providers/healthProfile/HealthProfileMetricsProvider';
import { HealthProfileProsthesisProvider } from '~/providers/healthProfile/HealthProfileProsthesisProvider';

interface Props {
  editing?: boolean;
  header?: React.ReactNode;
  hideBack?: boolean;
  onCancel?: () => void;
  onStartEditing?: (accountId: number) => void;
  backLink?: INavigate;
}

export const PatientEditableHealthProfile: React.FC<Props> = ({
  editing,
  header,
  onCancel,
  onStartEditing,
  backLink,
  hideBack,
}) => {
  const [showAuditLogs, setShowAuditLogs] = useState(false);
  const [auditLogsLoading, setAuditLogsLoading] = useState<boolean>(null);

  const viewRef = useRef(null);

  const healthProfileDataContext = useHealthProfileDataContext();

  const startEditing = () => {
    if (onStartEditing) onStartEditing(healthProfileDataContext.accountId);
  };

  const scrollToView = () => {
    if (viewRef?.current?.scrollIntoView) {
      viewRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const goToAndShowAuditLog = () => {
    if (showAuditLogs) scrollToView();
    else setShowAuditLogs(true);
  };

  useEffect(() => {
    if (!auditLogsLoading && showAuditLogs)
      setTimeout(() => {
        scrollToView();
      }, 50);
  }, [auditLogsLoading]);

  const cancel = () => {
    healthProfileDataContext.reset();
    if (onCancel) onCancel();
  };

  return (
    <GridLoadingWrapper loading={healthProfileDataContext.loading}>
      <PatientsLayout
        hideHeaderRight
        flexContent={false}
        back={{
          to: backLink,
        }}
        header={header}
        hideBack={hideBack}
        footer={
          <Grid>
            <Container fluid>
              <Row>
                <Column alignSelf="center" span={{ xs: 12, md: 6 }} style={mt15}>
                  <SmallNsRegularBlack>Any changes to the health profile will be recorded</SmallNsRegularBlack>
                </Column>
                {healthProfileDataContext.hasWriteAccess && editing ? (
                  <Column span={{ xs: 12, md: 6 }} style={mt15}>
                    <CancelContinueFooter
                      confirmTitle={
                        editing
                          ? healthProfileDataContext.accountId
                            ? labels.update
                            : labels.createProfile
                          : labels.edit
                      }
                      onCancel={cancel}
                      onConfirm={editing ? healthProfileDataContext.submit : startEditing}
                      waiting={healthProfileDataContext.waiting}
                      hideCancel={!editing}
                    />
                  </Column>
                ) : null}
              </Row>
            </Container>
          </Grid>
        }>
        <Grid
          grid={{
            gutters: 16,
            noOuterGutter: true,
          }}>
          <Container alignSelf="flex-start" style={flex1}>
            <Row style={{ minHeight: 42 }}>
              <Column>
                <H3TtmSemiBoldBlack style={mb10}>{labels.healthProfile} </H3TtmSemiBoldBlack>
              </Column>
              {healthProfileDataContext.healthProfile?.id && !editing ? (
                <Column>
                  <TextButton
                    funCallback={goToAndShowAuditLog}
                    label={labels.viewAuditLog.toUpperCase()}
                    style={{ alignSelf: 'flex-end' }}
                  />
                </Column>
              ) : null}
            </Row>
            <HealthProfileMetricsProvider>
              <PatientHealthMetrics locked={!editing} onStartEdit={startEditing} />
            </HealthProfileMetricsProvider>

            <View style={[separator, mv15]} />

            <PatientHistory locked={!editing} onStartEdit={startEditing} />

            <View style={[separator, mv15]} />

            <HealthProfileProsthesisProvider>
              <PatientProsthesis locked={!editing} onStartEdit={startEditing} />
            </HealthProfileProsthesisProvider>

            <View style={[separator, mv15]} />

            <PatientLifestyle locked={!editing} onStartEdit={startEditing} />

            <View style={[separator, mv15]} />

            {healthProfileDataContext.healthProfile?.id && !editing ? (
              <>
                <Row>
                  <Column style={[mv10, mh5]} span="auto">
                    <View>
                      <ShadowButton
                        label={showAuditLogs ? labels.hideAuditlogs : labels.viewAuditLogs}
                        style={ph15}
                        onPress={() => {
                          setShowAuditLogs(!showAuditLogs);
                        }}
                      />
                    </View>
                  </Column>
                </Row>
                {showAuditLogs ? (
                  <Row>
                    <Column>
                      <View ref={viewRef}>
                        <HealthProfileAuditLog
                          id={healthProfileDataContext.healthProfile.id}
                          setAuditLogsLoading={setAuditLogsLoading}
                        />
                      </View>
                    </Column>
                  </Row>
                ) : null}
              </>
            ) : null}
          </Container>
        </Grid>
      </PatientsLayout>
    </GridLoadingWrapper>
  );
};
