import { createApi } from '@reduxjs/toolkit/query/react';
import { CountryIndexResponse } from '~/api/models/referenceData/responses/CountryIndexResponse';
import { UPDATE_COUNTRY_LIST } from '../reducers/referenceDataReducer';
import { axiosBaseQuery } from './baseQuery';

export const referenceDataApi = createApi({
  reducerPath: 'referenceDataApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    countries: builder.query<CountryIndexResponse, void>({
      query: () => ({
        url: 'countries',
        method: 'get',
        data: undefined,
        params: undefined,
        headers: undefined,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(UPDATE_COUNTRY_LIST(data));
        } catch {}
      },
    }),
  }),
});

export const { useCountriesQuery } = referenceDataApi;
