import { useLinkTo } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { parse } from 'expo-linking';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import DoctorWebDashboard from '../../pages/dashboard/DoctorWebDashboard';
import DoctorWebDashboardPms from '../../pages/dashboard/DoctorWebDashboardPms';
import { PatientWebDashboard } from '../../pages/dashboard/PatientWebDashboard';
import { CalendarPageMobile } from '../../pages/doctor/calendar/CalendarPageMobile';
import { CalendarPageWeb } from '../../pages/doctor/calendar/CalendarPageWeb';
import { PatientAddNew } from '../../pages/doctor/patients/PatientAddNew';
import { PatientDetails } from '../../pages/doctor/patients/PatientDetails';
import { PatientDiagnosisDetails } from '../../pages/doctor/patients/PatientDiagnosisDetails';
import { PatientsListing } from '../../pages/doctor/patients/PatientsListing';
import { ConsultationDetailPage } from '../../pages/doctor/patients/consultation/ConsultationDetailPage';
import { SettingsAccount } from '../../pages/doctor/settings/SettingsAccount';
import { SettingsMobilePage } from '../../pages/doctor/settings/SettingsMobilePage';
import { SettingsNotifications } from '../../pages/doctor/settings/SettingsNotifications';
import { SettingsSecurity } from '../../pages/doctor/settings/SettingsSecurity';
import { SettingsWebPage } from '../../pages/doctor/settings/SettingsWebPage';
import { HelpWebPage } from '../../pages/help/HelpWebPage';
import { ProfileMobilePage } from '../../pages/profile/ProfileMobilePage';
import { ProfileWebPage } from '../../pages/profile/ProfileWebPage';
import { ProfileHomeAddress } from '../../pages/profile/common/ProfileHomeAddress';
import { ProfilePaymentDetails } from '../../pages/profile/common/ProfilePaymentDetails';
import {
  isClinicVersion,
  isDoctorVersion,
  isPatientVersion,
  isPharmacyVersion,
  whenAppType,
} from '../../utils/buildConfig';
import { screenName } from '../../utils/screenName';

import { useGeneralChannel } from '~/api/hooks/channels/useGeneralChannel';
import { usePharmacyChannels } from '~/api/hooks/channels/usePharmacyChannels';
import { usePrescriptionRequestsChannel } from '~/api/hooks/channels/usePrescriptionRequestsChannel';
import { usePrivateSubscriptionChannel } from '~/api/hooks/channels/usePrivateSubscription';
import { useAppointmentRequestHandling } from '~/api/hooks/notifications/AppointmentRequestHandlingHook';
import { useHasUnreadNotifications } from '~/api/hooks/notifications/HasUnreadNotificationsHook';
import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { useSetCurrentPharmacy } from '~/api/hooks/pharmacy/PharmacyHook';
import { usePrescriptionRequestsNotifications } from '~/api/hooks/prescriptions/PrescriptionRequestsHook';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { PatientBillingHistoryPage } from '~/components/subscriptions/PatientBillingHistoryPage';
import { ActionHandlerScreen } from '~/pages/actions/ActionHandlerScreen';
import { PatientAppointmentShowPage } from '~/pages/appointment/PatientAppointmentShowPage';
import DoctorMobileDashboard from '~/pages/dashboard/DoctorMobileDashboard';
import { PatientMobileDashboard } from '~/pages/dashboard/PatientMobileDashboard';
import { ClinicDashboard } from '~/pages/dashboard/clinic/ClinicDashboard';
import { PharmacistDashboard } from '~/pages/dashboard/pharmacist/PharmacistDashboard';
import { DoctorAppointmentShowPage } from '~/pages/doctor/calendar/appointments/DoctorAppointmentShowPage';
import { IncomeScreen } from '~/pages/doctor/income/IncomeScreen';
import { PatientDiagnosesScreen } from '~/pages/doctor/patients/PatientDiagnosesScreen';
import { PatientEditDetails } from '~/pages/doctor/patients/PatientEditDetails';
import { ConsultationCreatePage } from '~/pages/doctor/patients/consultation/ConsultationCreatePage';
import { ConsultationMobilePage } from '~/pages/doctor/patients/consultation/ConsultationMobilePage';
import { ConsultationHealthRecordMobile } from '~/pages/doctor/patients/consultation/mobile/ConsultationHealthRecordMobile';
import { ConsultationPrescriptionMobile } from '~/pages/doctor/patients/consultation/mobile/ConsultationPrescriptionMobile';
import { ConsultationPrivateNoteMobile } from '~/pages/doctor/patients/consultation/mobile/ConsultationPrivateNoteMobile';
import { ConsultationReferralsMobile } from '~/pages/doctor/patients/consultation/mobile/ConsultationReferralsMobile';
import { ConsultationSickLeaveMobile } from '~/pages/doctor/patients/consultation/mobile/ConsultationSickLeaveMobile';
import { PatientHealthProfile } from '~/pages/doctor/patients/healthProfile/PatientHealthProfile';
import { PatientHealthProfileEditing } from '~/pages/doctor/patients/healthProfile/PatientHealthProfileEditing';
import { PatientHealthProfileMobile } from '~/pages/doctor/patients/healthProfile/PatientHealthProfileMobile';
import { PatientHealthProfileLifestyleMobile } from '~/pages/doctor/patients/healthProfile/mobile/PatientHealthProfileLifestyleMobile';
import { PatientHealthProfileMetrics } from '~/pages/doctor/patients/healthProfile/mobile/PatientHealthProfileMetrics';
import { PatientHealthProfileProsthesisMobile } from '~/pages/doctor/patients/healthProfile/mobile/PatientHealthProfileProsthesisMobile';
import { PatientHealthProfileAllergiesMobile } from '~/pages/doctor/patients/healthProfile/mobile/history/PatientHealthProfileAllergiesMobile';
import { PatientHealthProfileMedicalConditionsMobile } from '~/pages/doctor/patients/healthProfile/mobile/history/PatientHealthProfileMedicalConditionsMobile';
import { PatientHealthProfileMedicationsMobile } from '~/pages/doctor/patients/healthProfile/mobile/history/PatientHealthProfileMedicationsMobile';
import { PatientHealthProfileProceduresMobile } from '~/pages/doctor/patients/healthProfile/mobile/history/PatientHealthProfileProceduresMobile';
import { PatientHealthProfileVaccinationsMobile } from '~/pages/doctor/patients/healthProfile/mobile/history/PatientHealthProfileVaccinationsMobile';
import { PermanentlyDeleteAccountAuthorised } from '~/pages/doctor/settings/PermanentlyDeleteAccountAuthorised';
import { SettingsConsultations } from '~/pages/doctor/settings/SettingsConsultations';
import { SettingsExportData } from '~/pages/doctor/settings/SettingsExportData';
import { HelpCancellationPolicy } from '~/pages/help/HelpCancellationPolicy';
import { HelpContactUsMobile } from '~/pages/help/HelpContactUsMobile';
import { HelpCookiePolicy } from '~/pages/help/HelpCookiePolicy';
import { HelpFaqMobilePage } from '~/pages/help/HelpFaqMobilePage';
import { HelpMobilePage } from '~/pages/help/HelpMobilePage';
import { HelpPrivacyPolicyPage } from '~/pages/help/HelpPrivacyPolicyPage';
import { HelpTermsAndConditionsPage } from '~/pages/help/HelpTermsAndConditionsPage';
import { AppointmentsBookingScreen } from '~/pages/patient/AppointmentsBookingScreen';
import { AppointmentsScreen } from '~/pages/patient/AppointmentsScreen';
import { DigimedEducationArticle } from '~/pages/patient/DigimedEducationArticle';
import { DigimedEducationCentre } from '~/pages/patient/DigimedEducationCentre';
import { PatientPrescriptionsScreen } from '~/pages/patient/PrescriptionsScreen';
import { DependantAddressScreen } from '~/pages/patient/dependants/DependantAddressScreen';
import { DependantDetailsMobileScreen } from '~/pages/patient/dependants/DependantDetailsMobileScreen';
import { DependantDetailsWebScreen } from '~/pages/patient/dependants/DependantDetailsWebScreen';
import { DependantHealthProfile } from '~/pages/patient/dependants/DependantHealthProfile';
import { DependantLinkedAccounts } from '~/pages/patient/dependants/DependantLinkedAccounts';
import { DependantPersonalInfoScreen } from '~/pages/patient/dependants/DependantPersonalInfoScreen';
import { DependantsAddNewScreen } from '~/pages/patient/dependants/DependantsAddNewScreen';
import { DependantsPaywallScreen } from '~/pages/patient/dependants/DependantsPaywallScreen';
import { DependantsScreen } from '~/pages/patient/dependants/DependantsScreen';
import { DoctorListingPage } from '~/pages/patient/doctors/DoctorsListingPage';
import { HealthProfileMobile } from '~/pages/patient/healthProfile/HealthProfileMobile';
import { MyHealthProfileLifestyleMobile } from '~/pages/patient/healthProfile/mobile/MyHealthProfileLifestyleMobile';
import { MyHealthProfileMetrics } from '~/pages/patient/healthProfile/mobile/MyHealthProfileMetrics';
import { MyHealthProfileProsthesisMobile } from '~/pages/patient/healthProfile/mobile/MyHealthProfileProsthesisMobile';
import { MyHealthProfileAllergiesMobile } from '~/pages/patient/healthProfile/mobile/history/MyHealthProfileAllergiesMobile';
import { MyHealthProfileMedicalConditionsMobile } from '~/pages/patient/healthProfile/mobile/history/MyHealthProfileMedicalConditionsMobile';
import { MyHealthProfileMedicationsMobile } from '~/pages/patient/healthProfile/mobile/history/MyHealthProfileMedicationsMobile';
import { MyHealthProfileProceduresMobile } from '~/pages/patient/healthProfile/mobile/history/MyHealthProfileProceduresMobile';
import { MyHealthProfileVaccinationsMobile } from '~/pages/patient/healthProfile/mobile/history/MyHealthProfileVaccinationsMobile';
import { InsuranceClaimFormScreen } from '~/pages/patient/insurance/InsuranceClaimFormScreen';
import { InsurancePage } from '~/pages/patient/insurance/InsurancePage';
import { PharmacistsProfileScreen } from '~/pages/pharmacy/PharmacistsProfileScreen';
import { PharmacyAwaitingCollectionScreen } from '~/pages/pharmacy/PharmacyAwaitingCollectionScreen';
import { PharmacyDispenseHistoryScreen } from '~/pages/pharmacy/PharmacyDispenseHistoryScreen';
import { PharmacyRequestHistoryScreen } from '~/pages/pharmacy/PharmacyRequestHistoryScreen';
import { PharmacyAccountDetailsScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacyAccountDetailsScreen';
import { PharmacyDetailsScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacyDetailsScreen';
import { PharmacyLocationScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacyLocationScreen';
import { PharmacySettingsMobileScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacySettingsMobileScreen';
import { ProfileClinic } from '~/pages/profile/ProfileClinic';
import { ProfilePersonalInformation } from '~/pages/profile/common/ProfilePersonalInformation';
import { ProfileSubscriptionPlan } from '~/pages/profile/common/ProfileSubscriptionPlan';
import { PharmacyUserManagementScreen } from '~/pages/registration/pharmacy/PharmacyUserManagementScreen';
import { ClinicRequestAssignScreen } from '~/pages/requests/ClinicRequestAssignScreen';
import { RequestsScreen } from '~/pages/requests/RequestsScreen';
import { SettingsClinic } from '~/pages/settings/SettingsClinic';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import {
  DigimedFeatures,
  DigimedMilestones,
  digimedFeatureEnabled,
  digimedMilestoneEnabled,
} from '~/utils/milestoneUtil';
import { HealthProfileAuditLogs } from '~/pages/doctor/patients/healthProfile/mobile/HealthProfileAuditLogs';
import { VerificationPendingDashboard } from '~/pages/dashboard/VerificationPendingDashboard';
import { useSelector } from 'react-redux';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { RootState } from '~/redux/reducers';
import { PharmacyStateEnum } from '~/api/models/pharmacy/constants/PharmacyStateEnum';

interface Props {
  initialRouteName?: string;
}

const Stack = createNativeStackNavigator();
export const AuthorisedStack: React.FC<Props> = ({ initialRouteName }) => {
  useHasUnreadNotifications();
  usePrescriptionRequestsNotifications();
  const { getRequests } = useApprovalRequestsList();

  const { isMobile } = useBreakpoints();
  const linkTo = useLinkTo();
  const { hasFeatureAccess } = useFeatureAccess();

  const currentPharmacy = useSelector(currentPharmacySelector);
  const status = useSelector((state: RootState) => state.accountsReducer.accountStatus);
  const [isApprovedPharmacy, setIsApprovedPharmacy] = useState<boolean>(true);

  const currentPharmacyStatus = useMemo(() => {
    if (!isPharmacyVersion()) {
      return status;
    } else {
      const isApproved = currentPharmacy?.pharmacy.state === PharmacyStateEnum.APPROVED;
      setIsApprovedPharmacy(isApproved);
      return isApproved;
    }
  }, [currentPharmacy, status]);

  useEffect(() => {
    if (initialRouteName) {
      try {
        let path = null;
        if (Platform.OS === 'web') {
          path = parse(initialRouteName).path;
        } else {
          path = parse(initialRouteName.replace('//', '///')).path;
        }

        linkTo('/' + path);
      } catch {
        // console.error('Unable to parse initial route:', initialRouteName);
      }
    }

    getRequests();
  }, []);

  usePrivateSubscriptionChannel();
  useGeneralChannel();
  useAppointmentRequestHandling();
  usePharmacyChannels();
  usePrescriptionRequestsChannel();
  useSetCurrentPharmacy();

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={initialRouteName ? undefined : screenName.Dashboard}>
      {/* INITIAL screen for all app versions */}
      {whenAppType({
        whenPatient: (
          <Stack.Screen
            name={screenName.Dashboard}
            component={isMobile ? PatientMobileDashboard : PatientWebDashboard}
          />
        ),
        whenDoctor: (
          <Stack.Screen
            name={screenName.Dashboard}
            component={
              !digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps)
                ? DoctorWebDashboardPms
                : isMobile
                ? DoctorMobileDashboard
                : DoctorWebDashboard
            }
          />
        ),
        whenPharmacist: (
          <Stack.Screen
            name={screenName.Dashboard}
            component={currentPharmacyStatus ? PharmacistDashboard : VerificationPendingDashboard}
          />
        ),
        whenClinic: <Stack.Screen name={screenName.Dashboard} component={ClinicDashboard} />,
      })}

      {/* COMMON*/}
      {isMobile ? (
        <>
          <Stack.Screen name={screenName.SettingsNotifications} component={SettingsNotifications} />
          <Stack.Screen name={screenName.Settings} component={SettingsMobilePage} />
          <Stack.Screen name={screenName.SettingsSecurity} component={SettingsSecurity} />
          <Stack.Screen name={screenName.SettingsAccount} component={SettingsAccount} />
          <Stack.Screen name={screenName.Help} component={HelpMobilePage} />
          <Stack.Screen name={screenName.HelpFaq} component={HelpFaqMobilePage} />
          <Stack.Screen name={screenName.HelpContactUs} component={HelpContactUsMobile} />
          <Stack.Screen name={screenName.HelpCookiePolicy} component={HelpCookiePolicy} />
          <Stack.Screen name={screenName.HelpPrivacyPolicy} component={HelpPrivacyPolicyPage} />
          <Stack.Screen name={screenName.HelpTermsAndConditions} component={HelpTermsAndConditionsPage} />
          {digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments) && !isPharmacyVersion() ? (
            <Stack.Screen name={screenName.HelpCancellationPolicy} component={HelpCancellationPolicy} />
          ) : null}
        </>
      ) : (
        <>
          <Stack.Screen name={screenName.Settings} component={SettingsWebPage} />
          <Stack.Screen name={screenName.Help} component={HelpWebPage} />
        </>
      )}

      {isPatientVersion() ? (
        // PATIENT
        <>
          <Stack.Screen name={screenName.ProfileHomeAddress} component={ProfileHomeAddress} />
          <Stack.Screen name={screenName.PrescriptionScreen} component={PatientPrescriptionsScreen} />
          <Stack.Screen name={screenName.ProfileSubscriptionPlan} component={ProfileSubscriptionPlan} />
          <Stack.Screen name={screenName.PatientBillingHistory} component={PatientBillingHistoryPage} />
          <Stack.Screen name={screenName.ProfilePaymentDetails} component={ProfilePaymentDetails} />
          <Stack.Screen name={screenName.ProfileScreen} component={isMobile ? ProfileMobilePage : ProfileWebPage} />
          <Stack.Screen name={screenName.ProfilePersonalInformation} component={ProfilePersonalInformation} />
          <Stack.Screen name={screenName.Diagnoses} component={PatientDiagnosesScreen} />
          <Stack.Screen name={screenName.PatientVersionDiagnosis} component={PatientDiagnosisDetails} />
          <Stack.Screen name={screenName.PatientVersionConsultation} component={ConsultationDetailPage} />
          <Stack.Screen name={screenName.EducationCentre} component={DigimedEducationCentre} />
          <Stack.Screen name={screenName.EducationArticle} component={DigimedEducationArticle} />

          {hasFeatureAccess(FeatureAccessEnum.FAMILY_GROUP) ? (
            <>
              <Stack.Screen name={screenName.Dependants} component={DependantsScreen} />
              <Stack.Screen name={screenName.DependantsAddNew} component={DependantsAddNewScreen} />

              {isMobile ? (
                <>
                  <Stack.Screen name={screenName.DependantsShow} component={DependantDetailsMobileScreen} />
                  <Stack.Screen name={screenName.DependantPersonalInfo} component={DependantPersonalInfoScreen} />
                  <Stack.Screen name={screenName.DependantAddress} component={DependantAddressScreen} />
                  <Stack.Screen name={screenName.DependantHealthProfile} component={DependantHealthProfile} />
                  <Stack.Screen name={screenName.DependantLinkedAccounts} component={DependantLinkedAccounts} />
                </>
              ) : (
                <Stack.Screen name={screenName.DependantsShow} component={DependantDetailsWebScreen} />
              )}
            </>
          ) : (
            <>
              <Stack.Screen name={screenName.Dependants} component={DependantsPaywallScreen} />
            </>
          )}

          {isMobile ? (
            <>
              <Stack.Screen name={screenName.HealthProfile} component={HealthProfileMobile} />
              <Stack.Screen name={screenName.HealthProfileHealthMetrics} component={MyHealthProfileMetrics} />
              <Stack.Screen name={screenName.HealthProfileAllergies} component={MyHealthProfileAllergiesMobile} />
              <Stack.Screen name={screenName.HealthProfileMedical} component={MyHealthProfileMedicalConditionsMobile} />
              <Stack.Screen name={screenName.HealthProfileMedications} component={MyHealthProfileMedicationsMobile} />
              <Stack.Screen name={screenName.HealthProfileProcedures} component={MyHealthProfileProceduresMobile} />
              <Stack.Screen name={screenName.HealthProfileProsthesis} component={MyHealthProfileProsthesisMobile} />
              <Stack.Screen name={screenName.HealthProfileVaccinations} component={MyHealthProfileVaccinationsMobile} />
              <Stack.Screen name={screenName.HealthProfileLifestyle} component={MyHealthProfileLifestyleMobile} />
            </>
          ) : null}

          <Stack.Screen name={screenName.Appointments} component={AppointmentsScreen} />
          <Stack.Screen name={screenName.AppointmentsBooking} component={AppointmentsBookingScreen} />
          <Stack.Screen name={screenName.AppointmentsShow} component={PatientAppointmentShowPage} />

          <Stack.Screen name={screenName.Doctors} component={DoctorListingPage} />

          <Stack.Screen name={screenName.Insurance} component={InsurancePage} />
          {hasFeatureAccess(FeatureAccessEnum.INSURANCE) ? (
            <Stack.Screen name={screenName.InsuranceClaimForm} component={InsuranceClaimFormScreen} />
          ) : null}
        </>
      ) : null}
      {isDoctorVersion() ? (
        //  DOCTOR
        <>
          {digimedFeatureEnabled(DigimedFeatures.IncomeAnalysis) && hasFeatureAccess(FeatureAccessEnum.INCOME) ? (
            <Stack.Screen name={screenName.Income} component={IncomeScreen} />
          ) : null}
          <Stack.Screen name={screenName.PatientAddNew} component={PatientAddNew} />
          <Stack.Screen name={screenName.PatientEditDetails} component={PatientEditDetails} />
          <Stack.Screen name={screenName.PatientNewConsultation} component={ConsultationCreatePage} />
          <Stack.Screen name={screenName.PatientHealthProfileEdit} component={PatientHealthProfileEditing} />
          <Stack.Screen name={screenName.HealthProfileAuditLogs} component={HealthProfileAuditLogs} />

          <Stack.Screen name={screenName.CalendarAppointmentsShow} component={DoctorAppointmentShowPage} />

          {!isMobile ? (
            <>
              <Stack.Screen name={screenName.ProfileScreen} component={ProfileWebPage} />
            </>
          ) : (
            <>
              {digimedFeatureEnabled(DigimedFeatures.Consultations) ? (
                <>
                  <Stack.Screen name={screenName.SettingsConsultations} component={SettingsConsultations} />
                </>
              ) : null}

              <Stack.Screen name={screenName.ProfileScreen} component={ProfileMobilePage} />
              <Stack.Screen name={screenName.ProfilePersonalInformation} component={ProfilePersonalInformation} />
              <Stack.Screen name={screenName.ProfileHomeAddress} component={ProfileHomeAddress} />
              {digimedFeatureEnabled(DigimedFeatures.Payments) && hasFeatureAccess(FeatureAccessEnum.IBAN) ? (
                <>
                  <Stack.Screen name={screenName.ProfileSubscriptionPlan} component={ProfileSubscriptionPlan} />
                  <Stack.Screen name={screenName.ProfilePaymentDetails} component={ProfilePaymentDetails} />
                </>
              ) : null}
            </>
          )}
        </>
      ) : null}
      {isPharmacyVersion() ? (
        <>
          <Stack.Screen name={screenName.ProfileScreen} component={PharmacistsProfileScreen} />
          <Stack.Screen name={screenName.PharmacyAwaitingCollection} component={PharmacyAwaitingCollectionScreen} />
          <Stack.Screen name={screenName.PharmacyDispenseHistory} component={PharmacyDispenseHistoryScreen} />
          <Stack.Screen name={screenName.PharmacyRequestHistory} component={PharmacyRequestHistoryScreen} />
          <Stack.Screen name={screenName.PharmacyUserManagement} component={PharmacyUserManagementScreen} />
          {isMobile ? (
            <>
              <Stack.Screen name={screenName.PharmacySettings} component={PharmacySettingsMobileScreen} />
              <Stack.Screen name={screenName.PharmacyDetailsAccount} component={PharmacyAccountDetailsScreen} />
              <Stack.Screen name={screenName.PharmacyDetailsLocation} component={PharmacyLocationScreen} />
            </>
          ) : (
            <>
              <Stack.Screen name={screenName.PharmacyDetails} component={PharmacyDetailsScreen} />
            </>
          )}
        </>
      ) : null}

      {isClinicVersion() ? (
        <>
          <Stack.Screen name={screenName.Income} component={IncomeScreen} />
          <Stack.Screen name={screenName.ProfileScreen} component={ProfileClinic} />
          <Stack.Screen name={screenName.SettingsClinic} component={SettingsClinic} />
        </>
      ) : null}

      {isClinicVersion() || isDoctorVersion() ? (
        <>
          <Stack.Screen name={screenName.PatientManagement} component={PatientsListing} />
          <Stack.Screen name={screenName.PatientDetails} component={PatientDetails} />
          <Stack.Screen name={screenName.PatientDiagnosis} component={PatientDiagnosisDetails} />

          {!isMobile ? (
            <>
              <Stack.Screen name={screenName.PatientHealthProfile} component={PatientHealthProfile} />
              <Stack.Screen name={screenName.PatientConsultation} component={ConsultationDetailPage} />
            </>
          ) : (
            <>
              <Stack.Screen name={screenName.PatientHealthProfile} component={PatientHealthProfileMobile} />
              <Stack.Screen
                name={screenName.PatientHealthProfileHealthMetrics}
                component={PatientHealthProfileMetrics}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileAllergies}
                component={PatientHealthProfileAllergiesMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileMedical}
                component={PatientHealthProfileMedicalConditionsMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileMedications}
                component={PatientHealthProfileMedicationsMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileProcedures}
                component={PatientHealthProfileProceduresMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileProsthesis}
                component={PatientHealthProfileProsthesisMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileVaccinations}
                component={PatientHealthProfileVaccinationsMobile}
              />
              <Stack.Screen
                name={screenName.PatientHealthProfileLifestyle}
                component={PatientHealthProfileLifestyleMobile}
              />

              <Stack.Screen name={screenName.PatientConsultation} component={ConsultationMobilePage} />
              <Stack.Screen
                name={screenName.PatientConsultationHealthRecord}
                component={ConsultationHealthRecordMobile}
              />
              <Stack.Screen
                name={screenName.PatientConsultationPrescription}
                component={ConsultationPrescriptionMobile}
              />
              <Stack.Screen
                name={screenName.PatientConsultationPrivateNotes}
                component={ConsultationPrivateNoteMobile}
              />
              <Stack.Screen name={screenName.PatientConsultationReferral} component={ConsultationReferralsMobile} />
              <Stack.Screen name={screenName.PatientConsultationSickLeave} component={ConsultationSickLeaveMobile} />
            </>
          )}
        </>
      ) : null}
      {(isClinicVersion() || isDoctorVersion()) && digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments) ? (
        <>
          {isMobile ? (
            <Stack.Screen name={screenName.CalendarPage} component={CalendarPageMobile} />
          ) : (
            <Stack.Screen name={screenName.CalendarPage} component={CalendarPageWeb} />
          )}

          <Stack.Screen name={screenName.ClinicConsultationRequest} component={ClinicRequestAssignScreen} />
        </>
      ) : null}

      <Stack.Screen name={screenName.Requests} component={RequestsScreen} />
      <Stack.Screen name={screenName.PermanentlyDeleteAccount} component={PermanentlyDeleteAccountAuthorised} />
      <Stack.Screen name={screenName.SettingsExportData} component={SettingsExportData} />
      <Stack.Screen name={screenName.Action} component={ActionHandlerScreen} />
    </Stack.Navigator>
  );
};
