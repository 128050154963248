import React from 'react';
import { useHealthProfile } from '~/api/hooks/healthProfile/HealthProfileHook';
import { INavigate } from '~/common/types/navigation/navigate';
import { AuditLogsMobile } from '~/components/doctor/patients/healthProfile/AuditLogsMobile';

interface Props {
  backLink: INavigate;
  patientId: number;
}

export const HealthProfileAuditLogsMobile: React.FC<Props> = ({ backLink, patientId }) => {
  const { healthProfileId, loading } = useHealthProfile({ accountId: patientId });

  return <AuditLogsMobile backLink={backLink} healthProfileId={healthProfileId} loading={loading} />;
};
