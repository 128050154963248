import EventEmitter from 'eventemitter3';

export enum AppEvents {
  PHARMACY_REQUESTS_UPDATE = 'PHARMACY_REQUESTS_UPDATE',
  PHARMACY_DISPENSED_UPDATE = 'PHARMACY_DISPENSED_UPDATE',
  DOCTOR_APPOINTMENTS_CHANGED = 'DOCTOR_APPOINTMENTS_CHANGED',
  DOCTOR_ENDED_APPOINTMENT = 'DOCTOR_ENDED_APPOINTMENT',
  PATIENT_APPOINTMENTS_CHANGED = 'PATIENT_APPOINTMENTS_CHANGED',
  PATIENT_APPOINTMENT_ENDED = 'PATIENT_APPOINTMENT_ENDED',
  DOCTOR_LOCATION_UPDATE = 'DOCTOR_LOCATION_UPDATE',
  SUBSCRIPTION_CHANGE = 'SUBSCRIPTION_CHANGE',
}

class AppEventHandler {
  private static _instance: AppEventHandler;

  private eventEmitter: EventEmitter;

  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public static get instance() {
    if (!this._instance) {
      this._instance = new AppEventHandler();
    }

    return this._instance;
  }

  public addListener(event: AppEvents, method: (...args: any[]) => void) {
    this.eventEmitter.addListener(event, method);

    return () => {
      this.eventEmitter.removeListener(event, method);
    };
  }

  public removeAllListeners(event: AppEvents) {
    this.eventEmitter.removeAllListeners(event);
  }

  public emit(event: AppEvents, args?: any[]) {
    if (args?.length) {
      this.eventEmitter.emit(event, ...args);
    } else {
      this.eventEmitter.emit(event);
    }
  }
}

export default AppEventHandler.instance;
